import React from 'react';
import axios from "axios";

import DialogOk from 'Components/Dialog/DialogOk';
import DialogYesNo from 'Components/Dialog/DialogYesNo';
import HintToMembers from 'Components/App/HintToMembers';
import Login from 'Components/Member/Login';
import Modal from 'Components/Core/Modal';
import NavigationTop from 'Components/App/NavigationTop';
import NavigationLeft from 'Components/App/NavigationLeft';
import MemberList from 'Components/Member/MemberList';
import Topics from 'Components/Forum/Topics';
import Pages from 'Components/PageStore/Pages';
import Sections from 'Components/PageStore/Sections';
import Smilies from 'Components/Forum/Smilies';
import Forum from 'Components/Forum/Forum';
import Settings from 'Components/Member/Settings';
import PhpInfo from 'Components/Admin/PhpInfo';
import SystemInfo from 'Components/App/SystemInfo';

export default function Content(props) {

	const x = props.x;
	const io = window.io;

	const conRef = React.useRef(null);
	const webAppRenderd = React.useRef(false);

	const [forumUnread, setForumUnread] = React.useState(0);
	const renderCount = React.useRef(1);

	const [dialog, setDialog] = React.useState([]);
	const [hint, setHint] = React.useState([]);
	const [module, setModule] = React.useState([]);
	const [navigation, setNavigation] = React.useState([]);

	let connection = conRef.current;
	let imageFullSize = null;

	React.useEffect(() => {
		x.was.addHint = addHint;
		x.was.addModule = addModule;
		x.was.addNavigation = addNavigation;
		x.was.getMemberList = getMemberList;
		x.was.removeDialog = removeDialog;
		x.was.removeHint = removeHint;
		x.was.removeModule = removeModule;
		if (renderCount.current === 1) {
			x.was.send = socketSend;
			socketConnect();
		}
		renderCount.current++;
		if (props.webAppReady) {
			if (!webAppRenderd.current) {
				if (props.login === true) {
					webAppRenderd.current = true;
					// addModule(<Login id="login" x={x} formId="login" title="Login" />)
				}
				else {
					x.was.webapp = 1;
					init();
					makeContent();
					webAppRenderd.current = true;
				}
			}
		}
	});

	async function getMemberList(orderField) {
		const getData = async (orderField) => {
			const url = 'Backend/webapp.php?module=Member&task=MemberList&active=1' + ((orderField === '') ? '' : '&order-field=' + orderField);
			const result = await axios.get(url);
			return result;
		}
		const result = await getData(orderField)
		if (result.data['returncode'] === 1) {
			setForumUnread(result.data.content[0].postingsunread);			
		}
		return result;
	}


	function socketConnect() {

		connection = io(document.location.href, { path: "/Websocket/" });
		x.was.webSocket = connection;
		
		connection.on('hint', (args) => {
			args = JSON.parse(args);
			x.wah.addHint(args.hint);
			return true;
		});
		
		connection.on('new-posting', (args) => {
			args = JSON.parse(args);
			if (typeof x.wam[args.module] !== 'undefined') {
				// x.wam[args.module].updatePostingsUnread(9);
				x.wam[args.module].reload();
			}
			x.was.loadActiveMember();
		});

		connection.on('read-posting', (args) => {
			args = JSON.parse(args);
			if (args.memberid === x.was.memberId) {
				console.log('Read Posting...');
				x.was.loadActiveMember();
				if (typeof x.wam[args.module] !== 'undefined') {
					x.wam[args.module].reload();
				}
			}
		});
	
	
		connection.on('login', (args) => {
			args = JSON.parse(args);
			x.was.loadActiveMember();
		});

		connection.on('logout', (args) => {
			args = JSON.parse(args);
			x.was.loadActiveMember();
		});

		connection.on('update', (args) => {
			console.log('Update Member...');
			/* 
			const dregg = new Error('I was called').stack;
			const stack = dregg.split('\n');
			console.log(dregg);
			console.log(stack[0]);
			console.log(stack[2]);
			*/
		});

		connection.on('smilies-update', (args) => {
			x.was.axGet('/Backend/webapp.php?module=System&task=ReloadSessionData', (result) => {
				x.was.setSmilies(result.data.content.smilies);
			});
		});

		connection.on('software-update', (args) => {
			window.location.reload();
		});

		connection.on('ping', (args) => {
			console.log('Ping.....' + args);
			return true;
		});
		
		connection.on('connect', (args) => {
			console.log('Connect...');
			x.wam.reloadForums();
			x.was.loadActiveMember();
		});

		connection.on('disconnect', (args) => {
			console.log('Disconnect...');
		});

	}

	function socketSend(type, args, handleResult = false) {
		args.member = x.was.memberName;
		args.memberid = x.was.memberId;
		// console.log(args);
		try {
			if (handleResult === false) {
				connection.emit(type, JSON.stringify(args));				
			}
			else {
				connection.emit(type, JSON.stringify(args), (response) => {
					// console.log('Response: ' + response);
					handleResult(JSON.parse(response));
				});
			}
		} catch (error) {
			console.log('socket.io error');
		}
	}

	function addDialog(obj) {
		x.wad.init(obj.props.id);
		obj = { tag: obj };
		setDialog(current => [...current, obj]);
	};

	function addHint(obj) {
		obj = { tag: obj };
		setHint(current => [...current, obj]);
	};

	const addModule = (obj) => {
		x.wam.init(obj.props.id);
		x.wam.current = obj.props.id;
		obj = { tag: obj };
		setModule(current => [...current, obj]);
	};

	const addNavigation = (obj) => {
		obj = { tag: obj };
		setNavigation(current => [...current, obj]);
	};


	function removeDialog(id) {
		delete x.wad[id];
		setDialog(prev => prev.filter(moduleTag => moduleTag.tag.props.id !== id))
	}

	function removeHint(id) {
		delete x.wah[id];
		setHint(prev => prev.filter(hintTag => hintTag.tag.props.id !== id))
	}

	function removeModule(id) {
		x.wam[id].onClose();
		delete x.wam[id];
		setModule(prev => prev.filter(moduleTag => moduleTag.tag.props.id !== id))
	}



	function init() {
		// Dialog-Definition
		x.wad.addTag(<DialogOk id="ok" x={x} />);
		x.wad.addTag(<DialogYesNo id="yesno" x={x} />);

		// Modul-Definition
		x.wam.addTag(<Forum id="forum" forumId="forum" x={x} forum={true} title={x.was.application} display="d-none" />);
		x.wam.addTag(<Forum id="admin" forumId="admin" x={x} forum={true} title={'Admin-Forum'} display="d-none" />);
		// x.wam.addTag(<Test id="test" x={x} title="Test" />);
		// x.wam.addTag(<Forum id="forum-admin" x={x} title="Admin-Forum" display="d-none" />);
		x.wam.addTag(<Smilies id="smilies" x={x} title="Smilies" display="d-box" />);
		x.wam.addTag(<Topics id="topics" x={x} title="Themen" display="d-box" />);
		x.wam.addTag(<MemberList id="member-list" x={x} active={false} title="Mitgliederliste" display="d-box" />);
		x.wam.addTag(<SystemInfo id="system-info" x={x} title="System-Information" display="d-box" />);
		x.wam.addTag(<HintToMembers id="hint-to-members" x={x} title="Nachricht an alle" display="d-box" />);
		x.wam.addTag(<Sections id="sections" x={x} title="Page Store Rubriken" display="d-box" />);
		x.wam.addTag(<Pages id="pages" x={x} title="Page Store Seiten" display="d-box" />);
		x.wam.addTag(<Settings id="settings" x={x} title="Einstellungen" display="d-box" />);
		x.wam.addTag(<PhpInfo id="php-info" x={x} title="PHP-Info" display="d-box" />);
	}

	function makeContent() {
		// Erzeuge Dialoge
		addDialog(x.wad.tags['ok']);
		addDialog(x.wad.tags['yesno']);
		// addDialog(x.wad.tags['dialog-form']);

		// Erzeuge Module
		if (x.was.isAdmin()) addModule(x.wam.tags['admin']);
		addModule(x.wam.tags['forum']);
	}

	function globalClick(event) {
		if (event.target.tagName === 'IMG' && !x.$(event.target).hasClass('smiley') && x.wam.isForum()) {
			checkImageClick(event.target);
			imageFullSize = event.target;
			if (!x.$(event.target).hasClass('img-full-size')) {
				x.$(imageFullSize).addClass('img-full-size');
			}
			else {
				x.$(imageFullSize).removeClass('img-full-size');
			}
		}
		else {
			x.$(imageFullSize).removeClass('img-full-size');
		}
	}

	function checkImageClick(target) {
		if (target !== imageFullSize) {
			if (imageFullSize) {
				x.$(imageFullSize).removeClass('img-full-size');
			}
		}
	}

	return (
			(props.webAppReady) 
				?
					<div key="content" id="content">
						<Modal id="modal" x={x} />
						{(props.login === false) 
							?
								<>
									<NavigationLeft id="navigation-left" x={x} />
									<NavigationTop id="navigation-top" forum={true} x={x} forumUnread={forumUnread} />
								</>
							:	
								<Login id="login" x={x} formId="login" title="Login" />							
						}
						{dialog.map(({ tag, id }) =>
							<React.Fragment key={"dialog-" + tag.props.id}> {tag}</React.Fragment>
						)}
						<div key="hint" id="hint" className="hint-frame" >
							{hint.map(({ tag, id }) =>
								<React.Fragment key={"hint-" + tag.props.id}> {tag}</React.Fragment>
							)}
						</div>
						{navigation.map(({ tag, id }) =>
							<React.Fragment key={"navigation-" + tag.props.id}> {tag}</React.Fragment>
						)}
						<div key="module" id="module" onClick={e => globalClick(e)} onScroll={e => globalClick(e)} className="flex-fill overflow-hidden" >
							{module.map(({ tag, id }) =>
								<React.Fragment key={"module-" + tag.props.id}>
									{(tag.props.forum === true) ? React.cloneElement(tag, { updateForum: props.renderForum }) : tag}
								</React.Fragment>
							)}
						</div>
					</div>
			:
					null
	);
}
