import React from 'react';
import ModuleTable from 'Components/Core/ModuleTable';
import FormElement from 'Components/Core/Form/FormElement';
import './Topics.css';

export default function Topics(props) {

    const x = props.x;
	const wafId = props.id;
	const dialogFormId = "form-" + props.id;

	const formFields =
		<React.Fragment>
			<FormElement type="text"
				x={x}  
				dialogId={dialogFormId}
				wafId={wafId}
				id="name"
				mandatory={true}
				title="Thema"
				fieldValue=""
				maxLength="20"
			/>
			<FormElement type="textarea"
				x={x}  
				dialogId={dialogFormId}
				wafId={wafId}
				id="description"
				mandatory={true}
				title="Beschreibung"
				fieldValue=""
				maxLength="200000"
			/>
			<FormElement type="checkbox" x={x}   wafId={wafId} id="disabled" title="Inaktiv" />
		</React.Fragment>


	return (
		<ModuleTable
			id={props.id}
			tableId="id"
			dialogFormId={dialogFormId}
			x={x}
			classes={`module-topics topic-list`}
			title={props.title}
			addDeleteRow={true}
			addTitle="Neues Thema anlegen"
			module="Posting"
			listTask="TopicList"
			deleteTask="DeleteTopic"
			onAddHeader={(addHeader) => {
				addHeader('Thema');
				addHeader('Beschreibung');
				addHeader('Deaktiviert');
			}}
			onAddColumn={(key, value) => {  
				switch (key) {
					case 'name':
					case 'description':
					case 'disabled':
						return value;
					default:
						return false;
				}
			}}
			onEditRow={(e, rowFields, rowId) => {
					x.waf.setAddSend(wafId, 'id', rowId);
					x.waf.set(wafId, 'name', rowFields.name);
					x.waf.set(wafId, 'description', rowFields.description);
					x.waf.set(wafId, 'disabled', rowFields.disabled);
					x.waf.hide(wafId, 'message');
					x.wad[dialogFormId].setTitle("Thema bearbeiten");
					x.wad[dialogFormId].show(() => { x.waf.focus(wafId, 'name') });
			}}
			formFields={formFields}
			formSendTask="SaveTopic"
		/>
	)


};

