import React from 'react';
import 'CSS/FormSelect.css';

export default function FormSelectText(props) {

    const x = props.x;

	const id = props.id + '-form';
	const dialogId = props.id + '-dialog';

	const wafId = x.wau.getProperty(props.wafId, props.id);
	const disabled = x.wau.getProperty(props.disabled, false);

	const refInput = React.useRef(null);


	x.waf.init(wafId);
	x.waf[wafId][id] = {};

	x.wad.init(dialogId);
	x.wad[dialogId].setValue = setSelectValue;

	React.useEffect(() => {
		x.waf.initField(
			wafId,
			id,
			refInput,
			(typeof props.default == 'undefined') ? '' : props.default,
			(typeof props.name == 'undefined') ? id : props.name
		);
		x.waf.setKey(wafId, id, props.defaultKey);
		x.waf.mandatory(wafId, id, (typeof props.mandatory === 'undefined') ? false : props.mandatory);
		if (typeof props.defaultKey !== 'undefined') x.waf.setKey(wafId, id, props.defaultKey);
		// makeOptions();
		return () => {
		}
	}, [props, refInput, id, wafId, x]);

	function setSelectValue(key, value) {
		if (typeof props.setSelect !== 'undefined') {
			props.setSelect(key, value)
		}
		else {
			x.waf.set(wafId, id, value);
			x.waf.setKey(wafId, id, key);
			x.waf.setValid(wafId, id, true);
			if (typeof props.afterSelect !== 'undefined') props.afterSelect(key, value)
		}
	}

	/*
	function reset() {
		x.waf.set(wafId, id, '');
		x.waf.setKey(wafId, id, 0);
		setSelected(false);
		if (typeof props.afterSelect !== 'undefined') props.afterSelect(0, '')

	}
	*/

	function showSelectionList() {
		x.wad[dialogId].showSelection();
	}

	const inputClick = (typeof props.inputClick === 'undefined' || !props.inputClick) ? showSelectionList : props.inputClick
	const onReloadClick = (typeof props.onReloadClick !== 'undefined') ? props.onReloadClick : () => {};
	const onResetClick = (typeof props.onResetClick !== 'undefined') ? props.onResetClick : () => {setSelectValue(0, '')};

	return (
		<div className={`input-group input-group-sm select-text ${(typeof props.classes !== 'undefined') ? props.classes : ''}`} >
			<input
				type="text"
				id={props.id}
				ref={refInput}
				title={props.title}
				className={`form-control form-control-sm form-select-input ${(props.hiddenInput) ? 'd-none' : ''}`}
				placeholder={props.title}
				readOnly={true}
				disabled={disabled}
				onClick={e => inputClick()}
			/>
			{ (typeof props.reloadIcon !== 'undefined' && props.reloadIcon) 
				?
					<span className="input-group-text input-reset" onClick={onReloadClick}>
						<span className={` bi bi-arrow-clockwise `}
							title="Reload"
						/>
					</span>
				:
					null
			}
			{ (typeof props.resetIcon !== 'undefined' && props.resetIcon) 
				?
					<span className="input-group-text input-reset" onClick={onResetClick}>
						<span className={` bi bi-x `}
							title="Auswahl löschen"
						/>
					</span>
				:
					null
			}
			{ (typeof props.customIcon !== 'undefined' ) 
				?
					<span className="input-group-text " onClick={(typeof props.customClick !== 'undefined') ? props.customClick : inputClick}>
						<span className={` bi ${props.customIcon} `}
							title={props.customTitle}
						/>
					</span>
				:
					null
			}
			
		</div>
	)
};
