import React from "react";
import FormDatePicker from "Components/Core/Form/FormDatePicker";
import 'CSS/Forum/PostingDay.css';

export default function PostingDay(props) {

    const x = props.x;

	var wafId = props.dialogId;
	x.waf.init(wafId);


	function dateSelected(date) {
		x.wam[props.moduleId].setTab('archive');
		x.wam[props.moduleId].archive.setSelectDate(date);
		x.wam[props.moduleId].archive.load();
	}

	return (
		<div className="div-posting-day">
			<FormDatePicker dateSelected={dateSelected}/>
		</div>
	);


}
