import React from 'react';
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/Member/DialogChangePassword.css';

export default function DialogChangePassword(props) {

    const x = props.x;
	const [error, setError] = React.useState(false);
	const wafId = props.id;

	x.waf.init(wafId, {
		"onFormChanged": onFormChanged,
		"onAfterSend": onAfterSend,
	});

	function onAfterSend(result) {
		if (result.returncode === 1) {
			x.wah.addHint('Kennwort geändert...');
			setError(false);
			x.wad[wafId].hide();
		}
		else {
			setError(true);
		}
	}

	function onFormChanged() {
		x.waf[wafId].enableSendButton(x.waf.get(wafId, 'old-password') > '' && validNewPassword());
	}

	function validNewPassword() {
		const oldPassword = x.waf.get(wafId, 'old-password');
		const newPassword = x.waf.get(wafId, 'new-password');
		return x.wau.validPassword(newPassword, oldPassword);
	}

	return (

		<Dialog id={wafId} x={x}   title="Kennwort ändern" classes={'dialog-change-password'} closeIcon="1" >
			<Form
				x={x}  
				wafId={wafId}
				classes="webapp-form"
				module="Member"
				sendTask="ChangePassword"
			>
				<FormElement x={x}   wafId={wafId} id="old-password" title="Aktuelles Kennwort" />
				<FormElement x={x}   wafId={wafId} id="new-password" title="Neues Kennwort" />
				<div id="hint">
					<div className={`form-group ${(error === true) ? 'd-block' : 'd-none'}`} >
						<label id="error" >Kennwort konnte nicht geändert werden.</label>
					</div>
					<div className={`form-group ${(error === false) ? 'd-block' : 'd-none'}`} >
						<label id="message" >8-20 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe und eine Ziffer.</label>
					</div>
				</div>
			</Form>
		</Dialog>

	)
};
