import React from 'react';
import Dialog from "Components/Core/Dialog";

export default function DialogYesNo(props) {

    const x = props.x;

	x.wad.init(props.id);

	function handleClose(event, result) {
		event.preventDefault();
		if (typeof result === 'undefined') {
			result = 0;
		}
		x.wad['yesno'].hide(result);
	}

	return (

		<Dialog id={props.id} x={x}   title={props.title} classes="dialog-yesno" closeIcon="0" modal={true}>
			<div className='div-button'>
				<button className="btn " type="button" onClick={(e) => handleClose(e, 1)} >Ja</button>
				<button className="btn " type="button" onClick={(e) => handleClose(e, 0)} >Nein</button>
			</div>
		</Dialog>

	)
};
