import React from 'react';
import MemberListBasis from 'Components/Member/MemberListBasis';
import 'CSS/App/NavigationLeft.css';
import 'CSS/Member/ActiveMemberList.css';


export default function NavigationLeft(props) {

    const x = props.x;

	return (
		<div id={props.id}  className="navigation-left">
			<MemberListBasis id="active-member-list" x={x} title="" active={true}  classes="active-member-list"/>
		</div>
	)
};
