import React from 'react';
import Dialog from "Components/Core/Dialog";
import FormElement from 'Components/Core/Form/FormElement';

export default function DialogOk(props) {

    const x = props.x;

	x.wad.init('ok');
	x.waf.init('ok');

	function handleClose() {
		x.wad['ok'].hide();
	}

	return (

		<Dialog id={props.id} x={x}   title={props.title} classes="dialog-ok" closeIcon="1" modal={true}>
			<FormElement type="div" x={x}   wafId={'ok'} readOnly={true} id="ok-text" title="Text" />
			<div className='div-button'>
				<button className="btn " type="button" onClick={event => handleClose()} >OK</button>
			</div>
		</Dialog>

	)
};
