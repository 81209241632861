import React from "react";

export default function Form(props) {

    const x = props.x;

	/* 
	
		Properties:

		wa
		wafId

		Optionale Properties:

		classes:			CSS-Klassen
		dialogId:			Falls definiert, wird ein "Abbruch"Button hinzugefügt, der die Hide-Funktion des Dialogs aufruft
		focusElement:		Dieses Element erhält nach dem vollständigen Rendern der Komponente den Focus
		module:				Falls readTask und/oder sendTask übergeben werden, muss diese Variable ebenfalls übergeben werden
							Diese Variable wird dann in readSettings und saveSettings für die URL verwendet
		cancelButton:		Falls false, wird kein Abbrechen-Button hinzugefügt
		sendButton:			Falls false, wird kein Speichern-Button hinzugefügt
		readTask:			Falls definiert, erfolgt das Laden der Daten über diese Komponente. Der Inhalt dieser Variable wird in read für die URL verwendet 
		sendTask:			Falls definiert, erfolgt das Speichern der Daten über diese Komponente. Der Inhalt dieser Variable wird in send für die URL verwendet 
		sendButtonTitle:	Überschreibt den Standard-Titel "Speichern"

	*/

	const wafId = props.wafId;

	const addUrl = (typeof props.addUrl == 'undefined') ? '' : '&' + props.addUrl; 
	const autocomplete = (typeof props.autocomplete == 'undefined') ? '' : props.autocomplete; 
	const classes = (typeof props.classes == 'undefined') ? '' : props.classes; 
	const onAfterSend = (typeof props.onAfterSend == 'undefined') ? () => {} : props.onAfterSend;
	const onBeforeSend = (typeof props.onBeforeSend == 'undefined') ? () => {} : props.onBeforeSend;
	const onSubmit = (typeof props.onSubmit == 'undefined') ? onSubmitDefault : props.onSubmit;
	const sendButtonTitle = (typeof props.sendButtonTitle == 'undefined') ? 'Speichern' : props.sendButtonTitle; 
	const sendTask = (typeof props.sendTask == 'undefined') ? false : props.sendTask; 
	const handleError = (typeof props.handleError == 'undefined') ? () => {} : props.handleError; 
	const renderCount = React.useRef(1);

	const [enableSendButton, setEnableSendButton] = React.useState((typeof props.enableSendButton === 'undefined') ? false : props.enableSendButton);

	x.waf.init(wafId, {
		"cancel":cancel,
		"ok":ok,
		"onFormChanged":onFormChanged,
		"onRead":() => {},
		"send":send,
		"enableSendButton": setEnableSendButton
	});


	React.useEffect(() => {
	
		if (renderCount.current === 1) {
			read();
		}
		renderCount.current++;
	});

	function read() {
		const readTask = (typeof props.readTask == 'undefined') ? false : props.readTask; 
		const module = (typeof props.module == 'undefined') ? false : props.module;
		if (readTask){
			x.waf[props.wafId].onBeforeRead();
			let url = '/Backend/webapp.php?module=' + module + '&task=' + readTask + addUrl;
			// console.log(url);
			x.was.axGet(url, handleRead);
		}
		else {
			x.waf[props.wafId].onRead();
		}
	}

	function handleRead(result) {
		if (result.data['returncode'] === 1) {
			x.waf[props.wafId].onAfterRead(result.data);
		}
		else {
			alert('Fehler beim Lesen der Daten');
		}
	}

	function onFormChanged() {
		setEnableSendButton(x.waf.checkChanged(wafId));
	}

	function onSubmitDefault(event) {
		event.preventDefault();
		x.waf[wafId].ok(event);
	}

	function cancel() {
		if (typeof props.dialogId !== 'undefined') {
			x.wad[props.dialogId].hide();
		}
	}

	function ok(event) {
		event.preventDefault();
		if (typeof props.dialogId !== 'undefined') { 
			x.wad[props.dialogId].hide();
		}
		x.waf[wafId].send(event);
	}

	function send(event) {
		try {
			if (typeof event != 'undefined') {
				event.preventDefault();
			}
			onBeforeSend();
			// x.waf[wafId].onBeforeSend();
			// alert(x.waf.send);
			let url = 'Backend/webapp.php?module=' + ((typeof props.module === 'undefined') ? '' : (props.module + '&task=' + sendTask) + addUrl );
			x.waf.send(wafId, url, handleResult, handleError);
		} catch (error) {
			console.log(alert(error.message));
		}
	}

	function handleResult(result) {
		if (result.data.returncode === 1) {
			setEnableSendButton(false);
		}
		x.waf[wafId].onAfterSend(result.data);
		onAfterSend(result.data);
	}

	return (
			<form key={wafId} className={` webapp-form  ${classes} `} onSubmit={event => onSubmit(event)} autoComplete={autocomplete} >
				{props.children}
				<div className="form-buttons">
					{(x.wau.getProperty(props.sendButton, true)) ? <button type="submit" disabled={!enableSendButton} className="btn " onClick={event => onSubmit(event)} >{sendButtonTitle}</button> : null}
					{(x.wau.getProperty(props.cancelButton, false)) ? <button type="button" className="btn " onClick={cancel} >Abbrechen</button> : null}
					{(typeof props.buttons == 'undefined') ? null : props.buttons}
				</div>
			</form>
	);

}
