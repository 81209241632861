import React from 'react';
import Block from 'Components/Core/Block';
import Table from 'Components/Core/Table';
import './Menu.css';

export default function Menu(props) {

	const x = props.x;

	x.wan.init(props.id);
	x.wan[props.id].show = show;
	x.wan[props.id].hide = hide;

	function hide() {
		x.wab[props.id].hide();
		x.wab.modal.hide();
	}

	function show() {
		x.wab[props.id].show();
		x.wab.modal.show();
	}

	return (
		<Block id={props.id} x={x} display="d-none" classes="no-modal" >
			<Table
				id={props.id + '-table'}
				x={x}
				loadTable="1"
				module={props.module}
				listTask={props.listTask}
				deleteTask={props.deleteTask}
				classes="table-menu"
				noTitle={false}
				onAddHeader={(addHeader) => {
					const header = [];
					if (props.parentId === '') {
						header.push(<i key="header-left" className="bi bi-house table-icon" onClick={e => x.was.activateModule('forum')}></i>);
					}
					else {
						header.push(<i key="header-left" className="bi bi-arrow-left table-icon" onClick={e => x.was.activateNavigation(props.parentId)}></i>);
					}			
					header.push(<span key="header-title" >{props.title}</span>);
					header.push(<i key="header-right" className="bi bi-x-lg table-icon" onClick={e => x.wan[props.id].hide()}></i>);
					addHeader(header);
				}}
				onAddColumn={(key, value) => {
					switch (key) {
						case 'title':
							return value;
						default:
							return false;
					}
				}}
				onLoad={
					() => {
						const data = [];
						props.menuSource.forEach(
							function (menu) {
								data.push(menu);
							}
						);
						return data;
					}
				}
				onRowEdit={(e, rowFields, rowId) => { props.menuClick(rowFields) }}
			/>

		</Block>
	)
}
