import React from "react";
import FormSelectText from "./Form/FormSelectText";
import DialogSelectText from "./Form/DialogSelectText";

export default function Module(props) {

    const x = props.x;

	const [display, setDisplay] = React.useState(props.display);
	
	const displayTitle = (typeof props.displayTitle === 'undefined') ? '' : props.displayTitle;
	const classes = (typeof props.classes != 'undefined') ? props.classes : '';


	React.useEffect(() => {
		x.wam.makeActiveWindowList();

		return () => {
            
        }

	});

	x.wam.init(props.id, {
		"title": props.title
	});

	x.wam[props.id].hide = hide;
	x.wam[props.id].show = show;

	function hide() {
		setDisplay('d-none');
	}

	function show() {
		setDisplay('d-box');
	}

	function closeModule() {
		x.was.removeModule(props.id);
		x.wam.activate('forum');
	}

	function makeOpenWindowsData() {
		return x.wam.makeActiveWindowList();
	}

	function makeContent() {
		let content = {};
		let close = {};
		const openWindowId = props.id + '-window-list';
		const openWindowTag = 
			<FormSelectText
				id={openWindowId}
				wafId={openWindowId}
				componentId={x.wau.getComponentId()}
				moduleId={props.id}
				x={x}  
				default=""
				defaultKey={0}
				title={props.title}
				customIcon={"bi-window-stack " + (x.was.privatePostingsUnreadCount > 0 ? 'text-danger' : '')}
				customTitle="Liste der aktiven Modulen"
				onReloadClick={ () => x.wam[props.id].reload()}
				setSelect= {(key, value) => {
					x.wam.activate(key);
					
				}}
				display="d-nonde"
			/> 

		const selectOpenWindowTag = 
				<DialogSelectText 
					id={openWindowId} 
					moduleId={props.id} 
					x={x}   
					onSetData={makeOpenWindowsData} 
					title="Aktive Fenster"
					classes="dialog-select-open-window"
				/>

		if (typeof props.closeButton === 'undefined' || props.closeButton ) {
			close = <i className={`bi bi-x-square module-icon module-close`} title="Schließen" onClick={e => closeModule()} />
		}
		else {
			close = <i className={`bi bi-x-square module-icon module-icon-disabled`} title="Schließen nicht möglich: Hauptmodul"   />
		}
		content =
			<div key={"module-frame-" + props.id} id={"module-frame-" + props.id} className={` webapp-module module-frame ${classes} ${display} `}>
				<div key={'module-title-' + props.id} id={'module-title-' + props.id} className={` module-head ${displayTitle}`}>
					<span className='module-head-title '>
						<span className='module-title' >
							{openWindowTag}
						</span>
					</span>
					{props.toolbar}
					<span className='module-head-right'>
						{close}
						{props.titleToolbar}
					</span>
				</div>
				<div key={"module-content-" + props.id} id={"module-content-" + props.id} className={` module-content  `} >
					{props.children}
					{selectOpenWindowTag}
				</div>
			</div>
		return content;
	}

	return (
		<React.Fragment>
			{ makeContent() }
		</React.Fragment>
	)

};

