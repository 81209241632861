import React from "react";

export default function WebappBlock(props) {

    const x = props.x;
	const renderCount = React.useRef(1);

	React.useEffect(() => {
		if (renderCount.current === 1) {
			// Zeiger auf Funktionen
			x.wab.addTag = addTag;
			x.wab.init = init;
			props.childRendered();			
		}
		renderCount.current++;
	});

	function addTag(tag) {
		x.wab.tags[tag.props.id] = tag;
	}

	function init(id, settings) {
		if (typeof x.wab[id] == 'undefined') {
			x.wab[id] = {};
			x.wab[id].hide = () => {};
			x.wab[id].init = () => {};
			x.wab[id].onAfterRender = () => {};
			x.wab[id].show = () => {};
			x.wab[id].toggleDisplay = () => {};
		}
		if (typeof settings != 'undefined') {
			x.wab[id] = Object.assign(x.wab[id], settings);
		}

	}



	return (
		<React.Fragment />
	);

}

