import React from "react";
import axios from "axios";
// import { toHaveDisplayValue } from "@testing-library/jest-dom/dist/matchers";

export default function WebappSystem(props) {

    const x = props.x;
	const renderCount = React.useRef(1);
	const defaultAction = () => { return false; };

	// Webapp Version
	x.was.version = '1.1';
	x.was.buildNo = 0; 	                                              		// Wird über init in App.js gesetzt


	// Variables
	x.was.smilies = {};
	x.was.smiliesSortTranslation = null;
	x.was.members = {};
	x.was.topics = {};
	x.was.postingsUnread = {'forum':0, 'admin':0};						// Wird in MemberListBasis und Postings gesetzt
	x.was.postingsLastUnread = {'forum':0, 'admin':0};					// Wird in MemberListBasis und Postings gesetzt
	x.was.postingsUnreadHint = [];										// Wird in MemberListBasis und Postings gesetzt. 
	x.was.postingsUnreadCount = 0;										// Wird in dieser Komponenten in der Funktion setFavicon gesetzt
	x.was.postingUnshrink = []											// Wird in der Komponente Posting gesetzt und enthält die postingno eines großen Postings, das komplett angezeigt wird. 
	x.was.privatePostingsUnreadCount = 0;									// Wird in dieser Komponenten in der Funktion setFavicon gesetzt
	x.was.webSocket = null;												// Wird in der Komponente WebappWebSocket gesetzt
	x.was.logTimout = null;												// Handle auf Timeout-Funktion. Wird in der Funktion errorLog gesetzt
	x.was.loadMemberListTimeOut = null;									// Handle auf Timeout-Funktion. Wird in der Funktion loadMemberList der Komponente MemberListBasis gesetzt


	// Functions
	x.was.addHint = defaultAction;
	x.was.addWatchClick = addWatchClick;
	x.was.delWatchClick = delWatchClick;
	x.was.addWatchKey = addWatchKey;
	x.was.delWatchKey = delWatchKey;
	x.was.addNewThread = addNewThread;
	x.was.axGet = axGet;
	x.was.axPost = axPost;
	x.was.getMemberList = defaultAction;									// Wird in der Komponente MemberListBasis gesetzt
	x.was.getTime = getTime;
	x.was.getProjectInfo = getProjectInfo;
	x.was.isAdmin = isAdmin;
	x.was.isMobileDevice = isMobileDevice;
	x.was.loadSoftwareUpdate = loadSoftwareUpdate;
	x.was.makeMemberList = defaultAction;									// Wird in der Komponente MemberListBasis gesetzt
	x.was.onClick = onClick;
	x.was.onKey = onKey;
	x.was.sendSmiliesUpdate = sendSmiliesUpdate;
	x.was.sendSoftwareUpdate = sendSoftwareUpdate;
	x.was.setFavicon = setFavicon;
	x.was.setSmilies = setSmilies;
	x.was.reportError = reportError;
	x.was.updatePostingsUnread = updatePostingsUnread;
	x.was.logout = logout;
	x.was.unlock = unlock;
	x.was.webAppLoaded = defaultAction;									// Wird in der Komponente Webapp gesetzt

	x.was.hostName = document.location.hostname;
	x.was.webapp = 0;                                                   	// Wird in Content.js und NavigationTop.js im useEffect-Hook gesetzt. 0: nicht gerendert, 1: ohne Module und Blöcke gerendert, 2: mit Module und Blöcke gerendert

	x.was.watchClick = [];
	x.was.watchKey = [];

	// Level 1=root, 2=admin, 3=user, 4=guest
	x.was.memberLevel = 4;												// Wird in app.js in der Function webappRendered gesetzt.  	
	x.was.memberLevelLabel = ['', 'Admin', 'Moderator', 'User', 'Gast'];
	x.was.avatarImage = '';												// Wird in MemberListBasis in der Funktion makeMemberList gesetzt
	x.was.memberId = -1;													// Wird in app.js in der Function webappRendered gesetzt. 	
	x.was.memberName = '';												// Wird in app.js in der Function webappRendered gesetzt.
	x.was.avatarText = 'X';												// Wird in app.js in der Function webappRendered gesetzt.

	React.useEffect(() => {

		if (renderCount.current === 1) {
			const getSystemData = async () => {
				/*
				let url = 'Backend/webapp.php?module=Member&task=GetMembers';
				let result = await axios.get(url);
				x.was.members = JSON.parse(result.data.content);
				*/

				let url = 'Backend/webapp.php?module=Posting&task=GetTopics';
				let result = await axios.get(url);
				x.was.topics = JSON.parse(result.data.content);
				// console.log(x.was.topics);
				props.childRendered();
			};
			getSystemData();
		}
		renderCount.current++;
	});


	// System Labels
	x.was.application = 'Forum';                                        		// Wird über init in App.js gesetzt

	// Settings
	x.was.darkTheme = false;                                             		// Wird über init in App.js gesetzt
	x.was.darkThemeVariant = false;                                      		// Wird über init in App.js gesetzt
	x.was.forumClass = '';	                                           		// Wird über init in App.js gesetzt
	x.was.forumHideContent = 'd-block';	                               		// Wird über init in App.js gesetzt
	x.was.forumHideDiscussion = 'd-block';                             		// Wird über init in App.js gesetzt
	x.was.forumHideImages = '';			                               		// Wird über init in App.js gesetzt
	x.was.forumHideOT = 'd-block';	         	                      		// Wird über init in App.js gesetzt
	x.was.forumHideAvatar = true;         	                      			// Wird über init in App.js gesetzt
	x.was.forumHideDate = 'd-block';	         	                      		// Wird über init in App.js gesetzt
	x.was.forumMemberColored = 0;		         	                      		// Wird über init in App.js gesetzt
	x.was.forumFontBold = 0;			         	                      		// Wird über init in App.js gesetzt
	x.was.forumSendWithEnter = 0;			         	                      	// Wird über init in App.js gesetzt

	// System Functions

	x.was.loadActiveMember = defaultAction	                        			// Zeigt auf die Function "makeActiveList" aus der MemberList-Komponente und wird aus dieser gesetzt.  
	x.was.activateNavigation = defaultAction;									// Zeigt auf die Function "activateNavigation" aus Webapp.js. Wird in Webapp.js gesetzt. 
	x.was.addModule = defaultAction;                      	         			// Zeigt auf die Function "addModule" aus Webapp.js. Wird in Webapp.js gesetzt. 
	x.was.addNavigation = defaultAction;                      		      		// Zeigt auf die Function "addNavigation" aus Webapp.js. Wird in Webapp.js gesetzt. 
	x.was.setModal = defaultAction;				                    			// Zeigt auf die Function "modal" aus Modal.js. Wird in Modal.js gesetzt. 
	x.was.renderForum = defaultAction;                            				// Zeigt auf die Function "updateForum" aus Webapp.js. Wird in Webapp.jss gesetzt. 
	x.was.removeModule = defaultAction;                            				// Zeigt auf die Function "removeModule" aus Webapp.js. Wird in Webapp.jss gesetzt. 
	// x.was.showMemberList = false;                                     			// Wird in App.js gesetzt, sobald sich die Größe des Fensters ändert. 

	function updatePostingsUnread() {
		const currentModule = x.wam.current;
		setPostingsUnread();
		// x.was.renderForum();
		for (const key in x.was.postingsUnread) {
			if ((key !== 'forum' && key !== 'admin' ) && typeof x.wam[key] === 'undefined') {
				const memberId = x.wau.getConversationalPartner(key);
				if(typeof x.was.members[memberId] !== 'undefined') {
					x.wam.openPrivateForum(memberId, x.was.members[memberId].name, x.was.members[memberId].level, false);
				}
			}
		}
		if (currentModule !== x.wam.current) {
			x.wam.activate(currentModule);
		}
	}

	function setFavicon(hRef) {
		const favicon = document.getElementById("favicon");
		favicon.href = hRef
	}

	function setPostingsUnread() {
		x.was.postingsUnreadCount = 0;
		x.was.privatePostingsUnreadCount = 0;
		for (const key in x.was.postingsUnread) {
			x.was.postingsUnreadCount += x.was.postingsUnread[key];
			x.was.privatePostingsUnreadCount = (key !== 'forum') ? x.was.privatePostingsUnreadCount += x.was.postingsUnread[key] : x.was.privatePostingsUnreadCount;
        }
		const projectInfo = getProjectInfo();
		const hRef = (x.was.postingsUnreadCount > 0) ? projectInfo.srcFaviconHint : projectInfo.srcFavicon;
		setFavicon(hRef);
		document.title = x.was.application + ' (' + x.was.postingsUnreadCount + ' ungelesene Nachrichten)';
	}

	function setSmilies(smilies) {
		smilies = JSON.parse(smilies);
		const smiliesSelect = {};
		Object.entries(smilies).forEach(([key, value]) => {
			Object.entries(value).forEach(([code, image]) => {
				// console.log('code: ' + code + ' - image: ' + image);
				smiliesSelect[code] = image;
			});
		});
		x.was.smilies = smiliesSelect;
		// console.log(x.was.smilies);
	}
	function axGet(url, handleResult) {
		// url = document.location.href + url;
		axios.get(x.wau.getUrl(url))
			.then(result => {
				handleResult(result);
			});
	}

	function axPost(url, data, handleResult) {
		// console.log(data);
		axios.post(x.wau.getUrl(url), data)
			.then(result => {
				handleResult(result);
			});
	}

	function isAdmin() {
		if (x.was.memberLevel < 3) {
			return true;
		}
		else {
			return false;
		}
	}

	function reportError(message) {
		addNewThread('admin', 0, message.stack)
	}	

	function isMobileDevice() {
		return ((document.body.clientWidth < 1024) ? true : false);
	}
	function getTime(date) {
		if (date === undefined) {
			date = new Date();
		}
		let time = (date.getHours() < 10 ? '0' : '') + date.getHours() + ':';
		time += (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ':';
		time += (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
		return (time);
	}

	function getProjectInfo() {
		const info = {};
		const url = (new URL(window.location));
		const hostName = url.hostname.split(".");
		const domainName = hostName[hostName.length - 2] + '.' + hostName[hostName.length - 1];
		info.srcFavicon = 'project/' + domainName + '/favicon.png';
		info.srcFaviconHint = 'project/' + domainName + '/favicon-hint.png';
		info.srcLogo = 'project/' + domainName + '/logo.png';
		return info;
	}

	function loadSoftwareUpdate() {
		localStorage.setItem('software-update', 1);
		window.location.reload();
	}

	function sendSoftwareUpdate() {
		x.was.send('software-update', {  });
	}

	function sendSmiliesUpdate() {
		x.was.send('smilies-update', {  });
		x.was.axGet('/Backend/webapp.php?module=System&task=ReloadSessionData', (result) => {
			// x.was.smilies = JSON.parse(result.data.content.smilies);
			x.was.setSmilies(result.data.content.smilies);

		});
	}

	function addWatchClick(id, funcRef) {
		x.was.watchClick[id] = funcRef;
	}

	function delWatchClick(id, funcRef) {
		if (id in x.was.watchClick) {
			delete x.was.watchClick[id];
		}
	}

	function addWatchKey(id, funcRef) {
		x.was.watchKey[id] = funcRef;
	}

	function delWatchKey(id, funcRef) {
		if (id in x.was.watchKey) {
			delete x.was.watchKey[id];
		}
	}

	function onClick(event) {
		for (const key in x.was.watchClick) {
			x.was.watchClick[key](event);
		}
	}

	function onKey(event) {
		for (const key in x.was.watchKey) {
			x.was.watchKey[key](event);
		}
	}

	function logout() {
		localStorage.removeItem('token');
		let url = 'https://'.concat(document.location.hostname, '/Backend/webapp.php?module=Member&task=Logout');
		axios.post(url).then(result => {
			x.was.send('logout', { "module": props.moduleId, "member": x.was.memberName, "memberlevel": x.was.memberLevel });
			sessionStorage.clear();
			window.location.reload();
		});
	}

	function unlock() {
		let url = 'Backend/webapp.php?module=Posting&task=Unlock';
		axios.post(url).then(result => {
			console.log('Database unlocked');
		});
	}

	function addNewThread(forumId, topicId, posting) {
		let url = 'Backend/webapp.php?module=Posting&task=SaveNewPosting&forumId=' + forumId;
		const fields = {};
		fields['posting'] = posting;
		fields['topicid'] = topicId;
		axios.post(url, fields).then(result => {
			return (result.data.returncode > 1) ? true : false;
		});
	}

	return (
		<React.Fragment />
	);


}
