import React from 'react';
import ModuleTable from 'Components/Core/ModuleTable';
import FormElement from 'Components/Core/Form/FormElement';
import './Sections.css';

export default function Sections(props) {

    const x = props.x;
	const wafId = props.id;
	const dialogFormId = "form-" + props.id;

	const formFields =
		<React.Fragment>
			<FormElement type="text"
				x={x}  
				wafId={wafId}
				id="name"
				mandatory={true}
				title="Rubrik"
				fieldValue=""
				maxLength="50"
			/>
			<FormElement type="textarea"
				x={x}  
				wafId={wafId}
				id="description"
				mandatory={false}
				title="Beschreibung"
				fieldValue=""
				maxLength="200000"
			/>
			<FormElement type="checkbox" x={x}   wafId={wafId} id="top" title="Zuerst anzeigen" />
			<FormElement type="checkbox" x={x}   wafId={wafId} id="hidden" title="Versteckt" />
			<FormElement type="checkbox" x={x}   wafId={wafId} id="disabled" title="Inaktiv" />

		</React.Fragment>

	return (
		<ModuleTable
			id={props.id}
			tableId="sectionId"
			dialogFormId={dialogFormId}
			x={x}
			classes={`module-page-store-sections section-table`}
			title={props.title}
			addDeleteRow={true}
			addTitle="Neue Rubrik anlegen"
			module="PageStore"
			listTask="SectionList"
			deleteTask="SectionDelete"
			onAddHeader={(addHeader) => {
				addHeader('Rubrik');
				addHeader('Beschreibung');
				addHeader('Top');
				addHeader('Versteckt');
				addHeader('Inaktiv');
			}}
			onAddColumn={(key, value) => {  
				switch (key) {
					case 'name':
					case 'description':
					case 'top':
					case 'hidden':
					case 'disabled':
						return value;
					default:
						return false;
				}
			}}
			onEditRow={(e, rowFields, rowId) => {
					x.waf.setAddSend(wafId, 'sectionId', rowId);
					x.waf.set(wafId, 'name', rowFields.name);
					x.waf.set(wafId, 'description', rowFields.description);
					x.waf.set(wafId, 'top', rowFields.top);
					x.waf.set(wafId, 'hidden', rowFields.hidden);
					x.waf.set(wafId, 'disabled', rowFields.disabled);
					x.waf.hide(wafId, 'message');
					x.wad[dialogFormId].setTitle("Rubrik bearbeiten");
					x.wad[dialogFormId].show(() => { x.waf.focus(wafId, 'name') });
			}}
			formFields={formFields}
			formSendTask="SectionSave"
		/>
	)


};

