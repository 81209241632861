import React from 'react';
import FormElement from "Components/Core/Form/FormElement";

export default function PostingElement(props) {

    const x = props.x;

	const [newThread, setNewThread] = React.useState([""]);
	const classes = (typeof props.classes === 'undefined') ? null : props.classes;
	const children = (typeof props.children === 'undefined') ? null : props.children;

	x.was.setNewThreadShort = setNewThread;

	function checkPaste(event) {
		let text = event.clipboardData.getData('text');
		const forumId = x.wam.getForumId();
		if (x.wau.checkPaste(text, true, openNewThread)) {
			event.preventDefault();
		}
		else if (text.split('\n').length > 1) {
			x.waf.set(forumId + '-new-thread', 'posting', text);
			x.wam.openNewThread()
			event.preventDefault();
		}
	}

	function openNewThread() {
		// let currentTitle = newThread;
		const forumId = x.wam.getForumId();
		x.wam[forumId].showDialogNewThread();
		x.wam[forumId].setTab('postings'); // Funktion wird in tabs.js definiert
		x.wam.activate(forumId);
	}

	function newThreadChanged(value) {
		setNewThread(value);
	}

	return (
		<FormElement
			type="text"
			id={props.id}
			wafId={props.wafId}
			x={x}  
			value={newThread}
			classes={classes}
			maxLength="500"
			placeholder="Neuer Beitrag"
			onPaste={checkPaste}
			onChange={event => newThreadChanged(event.target.value)}
			onFocus={props.onFocus}
			children={children}
			style={{ border: 'none' }}
			autoComplete="on"
		/>
	);

}
