import React from "react";
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/Forum/DialogNewImage.css';


export default function DialogNewImage(props) {

    const x = props.x;
	const image = React.useRef(null);
	
	var wafId = props.dialogId;
	x.waf.init(wafId);

	React.useEffect(() => {
		x.wad[props.id].setImage = setImage;
		x.wad[props.id].activate = activate;
		x.waf[wafId].ok = upload;
	});

	function setImage(newImage) {
		image.current = newImage;
	}

	function activate() {
		x.waf.setSource(props.moduleId + '-new-image', props.moduleId + '-image-from-clipboard', URL.createObjectURL(image.current));
		x.wad[props.moduleId + '-new-image'].show();
	}

	function upload(event) {
		const formData = new FormData();
		formData.append('image', image.current, 'image.png');
		x.was.axPost('Backend/webapp.php?module=Posting&task=ImageFromClipboard', formData, (result) => {
			if (result.data.returncode === 1) {
				x.wad[props.moduleId + '-new-image'].hide();
				const newThreadID = props.moduleId + '-new-thread';
				const answerID = props.moduleId + '-answer';
				// IDs überprüfen!!! 
				if (!x.wad[newThreadID].isVisible && !x.wad[answerID].isVisible ) {
					x.wad[newThreadID].show(() => { x.waf.focus(newThreadID, 'posting') });
				}
				if (x.wad[answerID].isVisible ) {
					addLink(answerID, result.data.content.fileName);
				}
				else {
					addLink(newThreadID, result.data.content.fileName);
				}
			}
			else {
				console.log('Image Upload Error!' );
			}
		})
	} 

	function addLink(wafId, fileName) {
		const url = '\r\n[img]Backend/image.php?file=' + fileName + '[/img]\r\n';
		const curPos = x.waf[wafId].elements['posting'].reference.current.selectionStart;
		const curVal = x.waf.get(wafId,'posting');
		const leftValue = curVal.substring(0, curPos);
		const rightValue = curVal.substring(curPos);
		// x.waf.set(wafId,'posting',leftValue + '[url=' + url + ']' + url + '[/url]' + rightValue);
		x.waf.set(wafId,'posting',leftValue + url + rightValue);
		x.waf.setCursorPos(wafId, 'posting', 0, 0);
		x.waf.focus(wafId, 'posting');
	}


	return (
		<Dialog id={props.id} x={x} title="Bild aus Zwischenablage importieren" classes="dialog-new-image ">
				<Form 	x={x}   
						wafId={wafId} 
						enableSendButton={true}
						sendButtonTitle="Datei hochladen"
						focusElement="name" 
						classes="dialog-new-image-form">	
						<div className="div-new-image">
							<FormElement type="image" x={x}   wafId={wafId} id={props.moduleId + '-image-from-clipboard'} />
						</div>
						<label id="login-error" >Bitte Bildrechte und Lizenzen beachten!</label>
				</Form>				
		</Dialog>

	);


}
