import React from 'react';
import Module from 'Components/Core/Module';
import DialogForm from 'Components/Core/DialogForm';
import Table from './Table';

export default function ModuleTable(props) {

    const x = props.x;

	const wafId = props.id;
	const dialogFormId = props.dialogFormId;

	const [loadTable, setLoadTable] = React.useState(1);

	const hideTable = (typeof props.hideTable === 'undefined') ? false : props.hideTable;
	const onAddColumn = (typeof props.onAddColumn === 'undefined') ? (key, value) => { return value } : props.onAddColumn;
	const onAddFooter = (typeof props.onAddFooter === 'undefined') ? () => { return null } : props.onAddFooter;
	const onAddHeader = (typeof props.onAddHeader === 'undefined') ? () => { return null } : props.onAddHeader;
	const onRowEdit = (typeof props.onEditRow === 'undefined') ? () => { return null } : props.onEditRow;

	React.useEffect(() => {
		if (x.was.memberLevel > 2) {
			x.waf.disable(wafId, 'name');
			x.waf.disable(wafId, 'description');
		}
	});

	x.wam.init(props.id, {
		"onClose": () => {
			delete x.waf[wafId];
		}
	})

	function reloadTable() {
		setLoadTable(currentValue => currentValue + 1);
	}
	
	function addRow() {
		x.waf.setAddSend(wafId, props.tableId, 0);
		x.waf.set(wafId, 'name', '');
		x.waf.set(wafId, 'description', '');
		x.waf.hide(wafId, 'message');
		x.wad[dialogFormId].setTitle(props.addTitle);
		x.wad[dialogFormId].show(() => { x.waf.focus(wafId, 'name') });
	}

	function onDeleteClick(e, rowFields) {
		props.onDeleteClick(e, rowFields);
	}

	const moduleToolbar =
		(x.was.memberLevel < 3)
			?
			<span className={` bi bi-plus-square module-icon  `} title={props.addTitle} onClick={() => addRow()}>
			</span>
			: null;

	return (
		<Module
			id={props.id}
			x={x}
			classes={`${props.classes}`}
			title={props.title}
			display="d-block"
			titleToolbar={
				<span>
					{moduleToolbar}
				</span>
			}
			onShow={reloadTable}
		>
			<Table 
				id={props.id}
				x={x} 
				hideTable={hideTable}
				loadTable={loadTable}
				module = {props.module}
				listTask={props.listTask}
				deleteTask={props.deleteTask}
				classes={props.classes} 
				noTitle={false} 
				addDeleteRow={props.addDeleteRow}
				onAddHeader={(addHeader) => { return onAddHeader(addHeader) }}
				onAddFooter={(addFooter) => { return onAddFooter(addFooter) }}
				onAddColumn={(key, value) => { return onAddColumn(key, value) }}
				onDeleteClick={onDeleteClick}
				onRowEdit={(e, rowFields, rowId) => { onRowEdit(e, rowFields, rowId) }}
			/>
			<DialogForm
				id={dialogFormId}
				x={x}
				wafId={wafId}
				title={props.formTitle}
				classes="dialog-new-Section"
				children={props.formFields}
				module={props.module}
				sendTask={props.formSendTask}
				sendButton={(x.was.memberLevel < 4) ? true : false}
				onAfterSend={reloadTable}
			/>
		</Module>
	)


};
