import React from 'react';
import Module from 'Components/Core/Module';
import MemberListBasis from './MemberListBasis';
import DialogAddMember from './DialogAddMember';

export default function MemberList(props) {

    const x = props.x;
	const wafId = props.id;
	const dialogId = "dialog-add-member";

	x.wam.init(props.id, {
		"onClose": () => {
			x.waf.destroy(wafId);
			x.was.removeDialog(dialogId);
		}
	})

	x.waf.init(wafId, {});

	// Module Icons 
	const moduleToolbar = (x.was.memberLevel < 3)
		?
		<span className={` bi bi-person-plus module-icon  `} title="Neues Mitglied anlegen" onClick={() => addMember()}>
		</span>
		:
		null;

	function addMember() {
		x.wad['dialog-add-member'].show(() => { x.waf.focus(wafId, 'member-name') });
	}


	return (
		<Module 
			id={props.id} 
			x={x} 
			title={props.title} 
			display="d-block" 
			titleToolbar={
				<span>
					{moduleToolbar}
				</span>
			}
		>
			<MemberListBasis id="member-listing" x={x} title="" active={false} classes="listing " orderField="membername" />
			<DialogAddMember id={dialogId} x={x} wafId={wafId} />

		</Module>
	)


};

