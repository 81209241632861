import React from 'react';
import Posting from './Posting';

export default function Postings(props) {

    const x = props.x;
	const [postingSource, setPostingSource] = React.useState([]);       // Enthält die Posting-Daten vom Server. Diese werden dann in der Funktion makePostings aufbereitet. 
	const [selectedPostingNo, setSelectedPostingNo] = React.useState(0);
	const [lastPostingNoRead, setLastPostingNoRead] = React.useState(0);
	const scrollToView = React.useRef(false);
	const onLoaded = x.wau.getProperty(props.onLoaded, () => {});
	const threadMode = x.wau.getProperty(props.threadMode, false);
		
	var selectDate = null           // Ein gesamter Tag soll gelesen werden.
	var selectThread = 0			// Ein gesamter Thread soll gelesen werden.    

	React.useEffect(() => {
		if (selectedPostingNo > 0 && scrollToView.current === true) {
			// scrollToView.current = false;
			const postingId = document.getElementById('posting-' + props.id + '-' + selectedPostingNo);
			if (postingId !== null) {
				postingId.scrollIntoView({ behavior: "smooth" })
				// setSelectedPostingNo(0);
			}
			else {
				console.log('Module-Id: ' + props.moduleId + ' - Posting: ' + postingId);
			}
		}
	});

	props.onRendered({
		"load": getPostings,
		"makePostings": makePostings,
		"setSelectDate": setSelectDate,
		"setSelectThread": setSelectThread,
		"updateLastPostingNoRead": setLastPostingNoRead,
	})

	function setSelectDate(value) {
		selectDate = value;
	}

	function setSelectThread(value) {
		selectThread = value;
	}

	function getPostings(selectedPostingNo = 0) {
		setSelectedPostingNo(selectedPostingNo);
		if (selectedPostingNo > 0) {
			scrollToView.current = true;
		}
		else {
			scrollToView.current = false;
		}
		const thread = (selectThread > 0) ? '&threadno=' + selectThread : '';
		const day = (selectDate !== null) ? '&date=' + selectDate : '';
		let url = 'Backend/webapp.php?module=Posting&task=Postings&forumId=' + props.forumId;
		url += '&topicid=' + ((props.moduleId === 'forum') ? x.wam[props.moduleId].getCurrentTopicId() : 0) + " " + thread + day;
		// url += '&topicid=' + x.waf.getKey("form-current-topic", props.ModuleId + "-select-topic-form") + " " + thread + day;
		// console.log('Reload: ' + props.moduleId);
		x.was.axGet(url, handleGetPostings);
	}

	function handleGetPostings(result) {
		setSelectDate(null);
		setSelectThread(0);
		if (result.data['returncode'] === 1) {
			setPostingSource(result.data['content']['postings']);
			setLastPostingNoRead(result.data['content']['last-posting-no-read']);
			x.was.postingsUnread[props.moduleId] = result.data['content']['posting-count'] - result.data['content']['last-posting-read'];
			x.was.updatePostingsUnread();
			onLoaded({
						"postingCount" : result.data['content']['posting-count'], 
						"last-posting-no-read" : result.data['content']['last-posting-no-read']
					});
		}
		else {
			x.wah.addHint('Kann Posting-Tabelle nicht lesen!');
		}
	}

	function setTopic(id, name) {
		x.wad[props.moduleId + '-select-topic-dialog'].setValue(id, name);
	}



	function makePostings(source = postingSource) {

		let postingTags = [];
		let postingCount = 0;
		let content = '';
		let title = '';
		const paddingLeft = (props.hideAvatar) ? 4 : 56;
		// console.time(props.id);
		if (source.length > 0) {
			source.forEach(
				function (item, index) {
					postingCount++;
					if (item.visible > -1) {
						if (item.postingno === item.threadno) {
							postingTags.push(
								<Posting
									key={postingCount}
									id={props.id + '-' + item.postingno}
									threadMode={threadMode}
									x={x}  
									moduleId={props.moduleId}
									mode="thread"
									posting={item}
									getPostings={getPostings}
									selectedPostingNo={selectedPostingNo}
									hideContent={props.hideContent}
									hideDiscussion={props.hideDiscussion}
									hideOT={props.hideOT}
									hideAvatar={props.hideAvatar}
									hideDate={props.hideDate}
									showDialogAnswer={props.showDialogAnswer}
									content={content}
									title={title}
									paddingLeft={paddingLeft}
									setTopic={setTopic}
									postingQuote={props.postingQuote}
									copyToClipboard={props.copyToClipboard}
									lastPostingRead={lastPostingNoRead}
								/>
							);
						}
						else {
							postingTags.push(
								<Posting
									key={postingCount}
									id={props.id + '-' + item.postingno}
									threadMode={threadMode}
									x={x}  
									moduleId={props.moduleId}
									thread={false}
									mode="answer"
									posting={item}
									getPostings={getPostings}
									selectedPostingNo={selectedPostingNo}
									hideContent={props.hideContent}
									hideDiscussion={props.hideDiscussion}
									hideOT={props.hideOT}
									hideAvatar={props.hideAvatar}
									hideDate={props.hideDate}
									showDialogAnswer={props.showDialogAnswer}
									content={content}
									title={title}
									paddingLeft={paddingLeft}
									setTopic={setTopic}
									postingQuote={props.postingQuote}
									copyToClipboard={props.copyToClipboard}
									lastPostingRead={lastPostingNoRead}
								/>
							);
						}
					}
				}
			)
		}
		// console.timeEnd(props.id);
	
		// $('#forum-' + selectedPostingNo).animate({ scrollTop: 0 }, 600);
		// console.log('#content-' + selectedPostingNo);
		//setPostings(postingTags);
		return postingTags;
	}
	return (<div id={props.id + '-div'} className={`
											posting-parent 
											${props.classes} 
											${(props.memberColored === 1) ? "member-colored" : ""} 
											${(props.fontBold === 1) ? "posting-content-font-bold" : "posting-content-font-normal"}
										`}>
		<div id={props.id + '-postings'} className={`forum ${(props.forumScroll === 1) ? 'forum-postings-scroll' : 'forum-postings'} ${(props.hideImages === 1) ? 'hide-images' : ''}`}>
			{makePostings()}
		</div>
	</div>
	)
};
