import React from 'react';
import 'CSS/FormSelect.css';

export default function FormSelectIcon(props) {

    const x = props.x;

	const id = props.id + '-form';
	const dialogId = props.id + '-dialog';

	const classes = (typeof props.classes === 'undefined') ? '' : props.classes;

	x.wad.init(dialogId);
	x.wad[dialogId].setValue = setSelectValue;

	React.useEffect(() => {
		return () => {
		}
	}, [props]);

	function setSelectValue(key, value) {
		// console.log(key + '-' + value);
		props.onSelect(key, value);
	}

	function showSelectionList() {
		x.wad[dialogId].showSelection();
	}


	return (
		<span id={id} className={` ${classes} `}
			title="Aktive Fenster"
			onClick={e => showSelectionList()}
		/>
	)
};
