import React, { useEffect } from "react";
import 'CSS/Tabs.css'
export default function Tabs(props) {

    const x = props.x;
	const renderCount = React.useRef(1);
	
	useEffect(() => {
		if (renderCount.current === 1) {
			//makeTabs()
		}
		renderCount.current++;
	});

	x.wam[props.moduleId].setTab = props.tabClicked;

	function click(item) {
		props.tabClicked();
		item.click();
	}

	function makeTabs(tabsSource, tabSelected, clickFunction) {

		let tabTags = [];
		let tabCount = 1;
		Object.entries(tabsSource).forEach(([key, value]) => {
			// console.log(`${key} ${value}`);
			tabTags.push(			
				<i key={props.id + '-' + key} id={props.id + '-' + key} className={` tab ${'tab-' + tabCount} ${(key === tabSelected) ? "tab-selected" : ""} `} 
					onClick={(e) => click(value)}>
					{value.title}
				</i>
			)
			tabCount++;
		});
		return tabTags;
		// setTabs (tabTags);
	}

	return (
		<div id={props.id} className={` ${(typeof props.classes == 'undefined') ? '' : props.classes} ${(typeof props.showTabs == 'undefined') ? '' : props.showTabs} tabs `} >
			{makeTabs(props.tabs, props.tabSelected, props.tabClicked)}
			<span className='tabs-toolbar'>
				{props.toolbar}
			</span>
		</div>

	);


}
