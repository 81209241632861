import React, { useEffect } from "react";
import MemberProfile from "Components/Member/MemberProfile";
import Forum from "Components/Forum/Forum";

export default function WebappModule(props) {

	const x = props.x;
	const renderCount = React.useRef(1);

	useEffect(() => {
		if (renderCount.current === 1) {
			x.wam.tags = (typeof x.wam.tags === 'undefined') ? [] : x.wam.tags;
			x.wam.current = 'forum';							// Wird in der Funktion Init der Komponente App.js gesetzt 
			x.wam.activeWindows = [{}];							// Wird in der Funktion makeActiveWindowList gesetzt 

			x.wam.activate = activate;
			x.wam.addTag = addTag;
			x.wam.exists = exists;
			x.wam.hideAll = hideAll;
			x.wam.init = init;
			x.wam.getForumId = getForumId;
			x.wam.isForum = isForum;
			x.wam.openNewThread = openNewThread;
			x.wam.openPrivateForum = openPrivateForum;
			x.wam.openProfile = openProfile;
			x.wam.makeActiveWindowList = makeActiveWindowList;
			x.wam.reloadForums = reloadForums;
			x.wam.showPosting = showPosting;
			props.childRendered();
		}
		renderCount.current++;
	});

	function activate(id) {
		hideAll();
		if (!exists(id)) {
			init(id);
			x.was.addModule(x.wam.tags[id]);
		}
		x.wam.current = id;

		// activeWindows Array neu erzeugen
		makeActiveWindowList()

		// Anzeige der offenen Fenster in der linken Navigation aktualisieren 
		x.wam[id].show();
	}

	function addTag(tag) {
		x.wam.tags[tag.props.id] = tag;
	}

	function exists(id) {
		return !(typeof x.wam[id] == 'undefined');
	}

	function hideAll() {
		for (const key in x.wam) {
			if (typeof x.wam[key].hide != 'undefined') {
				x.wam[key].hide();
			}
		}
	}

	function reloadForums() {
		for (const key in x.wam) {
			if (isForum(key)) {
				console.log('Reload Forum: ' + key);
				x.wam[key].postings.load();
			}
		}
	}


	function init(id, settings) {
		if (typeof x.wam[id] == 'undefined') {
			x.wam[id] = {};
			x.wam[id].debug = () => { };
			x.wam[id].hide = () => { };
			x.wam[id].init = () => { };
			x.wam[id].onClose = () => { };
			x.wam[id].onDialogClose = () => { };
			x.wam[id].reload = () => { };
			x.wam[id].show = () => { };
			x.wam[id].scrollTop = () => { };
			x.wam[id].moduleType = 'other';
			x.wam[id].title = 'Module';
			x.wam[id].clickStack = [];				// Stapel zum Speichern von Click-Handlern der jeweiligen Module
			x.wam[id].keyStack = [];				// Stapel zum Speichern von Key-Handlern der jeweiligen Module
			x.wam[id].quote = '';
			x.wam[id].quotePosting = 0;
			x.wam[id].lastPostingNo = 0;
			x.wam[id].currentPostingNo = 0;
		}

		if (typeof settings != 'undefined') {
			x.wam[id] = Object.assign(x.wam[id], settings);
		}
		/*
				defaultValues = (typeof defaultValues == 'undefined') ? {} : defaultValues;
				Object.entries(defaultValues).forEach(([key, value]) => {
					x.wam[id][key] = value;
				});
				x.wam.current = id;
		*/
	}

	function makeActiveWindowList() {
		x.wam.activeWindows = [];
		// "id":"forum", "text":"Forum"
		for (const [key, value] of Object.entries(x.wam)) {
			if (typeof value.moduleType !== 'undefined') {
				if (value.moduleType !== 'system') {
					x.wam.activeWindows.push({ "id": key, "name": value.title + makeUnreadHint(x.wau.getConversationalPartner(key)) });
				}
			}
		}
		x.wam.activeWindows.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
		return x.wam.activeWindows;
	}

	function makeUnreadHint(memberId) {
		let hint = '';
		for (const key in x.was.postingsUnread) {
			if (x.wau.getConversationalPartner(key) === memberId) {
				hint = ' (' + x.was.postingsUnread[key] + ')';
				break;
			}
		}
		return hint;
	}

	function showPosting(postingNo) {
		x.wad['ok'].setTitle('Zitiere Posting');
		x.waf.set('ok', 'ok-text', 'Füge Posting Nr. ' + postingNo + ' ein...');
		x.wad['ok'].show();
		return false;
	}

	function getForumId() {
		var forumId = x.wam.current;   // Das aktuelle Modul ist ein Forum
		if (x.wam.current === '') {
			forumId = 'forum';        // Es gibt kein aktuelles Modul
		}
		else {
			if (x.wam[forumId].moduleType !== 'forum') {
				forumId = 'forum';        // Das aktuelle Modul ist kein Forum
			}
		}
		// console.log('ForumId: ' + forumId);
		return forumId;
	}


	function isForum(forumId = x.wam.current) {
		// console.log('isForum: ' + forumId);
		return (x.wam[forumId].moduleType === 'forum');
	}

	function openNewThread() {
		var forumId = x.wam.getForumId();
		x.wam[forumId].showDialogNewThread();
		x.wam[forumId].setTab('postings'); // Funktion wird in tabs.js definiert
		x.wam.activate(forumId);
	}

	function openProfile(memberId, memberName, memberLevel) {
		x.wam.hideAll();
		if (x.was.memberId !== memberId) {
			let moduleId = 'member-profile-' + memberId;
			if (x.wam.exists(moduleId)) {
				x.wam.activate(moduleId);
			}
			else {
				let newTag = <MemberProfile id={moduleId} memberId={memberId} memberName={memberName} memberLevel={memberLevel} x={x} title={memberName + " (Profil)"} display="d-block" />;
				x.was.addModule(newTag);
			}
		}
		else {
			// Eigene Einstellungen öffnen
			x.wam.activate('settings');
		}

	}

	function openPrivateForum(memberId, memberName, memberLevel, show = true) {
		hideAll();
		if (x.was.memberId !== memberId) {
			let forumId = x.wau.getPrivateForumId(memberId);
			if (exists(forumId)) {
				activate(forumId);
			}
			else {
				// let title = <i className="bi bi-person-fill module-icon"></i> 
				let newForumTag = <Forum
					id={forumId}
					key={forumId}
					forumId={forumId}
					memberId={memberId}
					memberName={memberName}
					memberLevel={memberLevel}
					x={x}
					title={memberName}
					display={(show) ? "d-block" : "d-none"}
					forum={true}
				/>;
				x.was.addModule(newForumTag);
				console.log('add new Module: ' + forumId);
			}
		}
		else {
			// Keine private Unterhaltung mit sich selbst möglich. Daher eigene Einstellungen öffnen
			activate('settings');
		}

	}



	return (
		<React.Fragment />
	);

}

