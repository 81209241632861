import React from 'react';
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/Member/DialogChangePassword.css';

export default function DialogSetPassword(props) {

    const x = props.x;
	const wafId = props.wafId;
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		x.waf.init(wafId, {
			"onFormChanged": onFormChanged,
			"onAfterSend": onAfterSend
		});
	});

	function onAfterSend(result) {
		if (result.returncode === 1) {
			x.wah.addHint('Kennwort gesetzt.');
			setError(false);
			x.wad[props.id].hide();
		}
		else {
			setError(true);
		}
	}

	function onFormChanged() {
		x.waf[wafId].enableSendButton(x.wau.validPassword(x.waf.get(wafId, 'new-password')));
	}

	return (

		<Dialog id={props.id} x={x} title="Kennwort setzen" classes={'dialog-change-password'} closeIcon="1" >
			<Form
				x={x}
				wafId={wafId}
				classes="webapp-form"
				module="Member"
				sendTask="SetPassword"
			>
				<FormElement x={x} wafId={wafId} id="member-id" title="ID" default={props.memberId} disabled />
				<FormElement x={x} wafId={wafId} id="new-password" title="Neues Kennwort" maxLength="20" />
				<div id="hint">
					<div className={`form-group ${(error === true) ? 'd-block' : 'd-none'}`} >
						<label id="error" >Kennwort konnte nicht geändert werden.</label>
					</div>
					<div className={`form-group ${(error === false) ? 'd-block' : 'd-none'}`} >
						<label id="message" >8-20 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe und eine Ziffer.</label>
					</div>
				</div>
			</Form>
		</Dialog>

	)
};
