import React from 'react';
import Dialog from '../Dialog';
import Listing from 'Components/Core/Listing';
import './DialogSelectText.css';

export default function DialogSelectText(props) {

    const x = props.x;

	const id = props.id + '-dialog';
	const refInput = React.useRef(null);
	const wafId = (typeof props.wafId === 'undefined') ? 'dialog-select-text' : props.wafId

	x.wad.init(id);
	x.wad[id].showSelection = showSelection;

	var lf;

	x.waf.init(wafId);
	x.waf[wafId][id] = {};
//	x.waf[wafId][id].reset = reset;

	function registerListing(listingFunctions) {
		lf = listingFunctions;
	}

	React.useEffect(() => {
		if (props.makeTable > 0) {
			// makeList();			
		}
	});

	React.useEffect(() => {
		if (typeof props.componentId !== 'undefined') {
			x.was.addWatchKey(props.componentId, (event) => {
				if (event.keyCode === 27) {
					 x.waf.focus('new-thread-short', 'posting');
				}
			});				
		}
		return () => {
            x.was.delWatchKey(props.componentId);
        }
	}, [props, refInput, x]);

	function showSelection() {
		makeList();
		x.wad[id].show();
	}

	function select(selectId, selectName) {
		x.wad[id].hide();
		x.wad[id].setValue(selectId, selectName);
	}

	async function makeList() {
		lf.reset();
		// addHeader();

		const data = (typeof props.onSetData !== 'undefined') ? await props.onSetData() : [];
		if (data) {
			// memberCount = topics.length;
			data.forEach(
				function (item) {
					// console.log('Online Count: ' + onlineCount);
					lf.addRow(
						[item.name, item.description], 
						{"id": item.id, "name":item.name}, 
						(item.disabled===1) ? 'row-disabled' : '', 
						(e, itemClicked) => {
							// console.log(itemClicked);
							select(itemClicked.id, itemClicked.name);
						}
					);
				}
			)
		}

		lf.makeListing();
	}

	/*
	function makeOptions() {

		const data = (typeof props.data === 'undefined') ? getDataFromServer() : props.data;

		const optionTags = [];
		let nextKey = 1;
		Object.entries(data).forEach(([key, value]) => {
//			if (refInput.current.value.length === 0 || value.name.toLowerCase().includes(refInput.current.value.toLowerCase())) {
			optionTags.push(<option key={nextKey} value={value.name} />);
			nextKey++;
			// console.log(`${key} ${value.name}`);
		});

		setOptions(
			<React.Fragment key={"form-select" + id}>
				{optionTags}
			</React.Fragment>
		);
	}
	*/
	return (
		<Dialog id={id} x={x}   classes={`dialog-get-text ${props.classes}`} title={props.title} >
			<Listing id={id + '-listing'} x={x}   registerListing={registerListing}  classes="select-text-list " />
		</Dialog>
	)
};
