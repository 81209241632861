import React from "react";
import Module from 'Components/Core/Module';
import Form from "Components/Core/Form/Form";
import FormElement from '../Core/Form/FormElement';
import 'CSS/SystemInfo.css';

export default function SystemInfo(props) {

    const x = props.x;
	const wafId = props.id;
	const renderCount = React.useRef(1);

	x.wam.init(props.id, {
		"title": 'System Informationen',
		"init": init,
		"onClose": onClose
	});

	React.useEffect(() => {
		if (renderCount.current === 1) {
			init();
		}
		renderCount.current++;
	})

	function init() {
		x.waf.set(wafId, 'system-info', x.wau.getStatus(), false);
	}

	function onClose() {
		x.waf.destroy(wafId);
	}
	return (
		<Module id={props.id} x={x} title={props.title} display={props.display}  displayTitle="d-block" closeButton="1">
			
			<Form 	x={x}   
					wafId={wafId} 
					sendButtonTitle="Senden"
					enableSendButton={true}
					module="System"
					sendTask="SystemInfo"
					classes="system-info">	
					<FormElement 
						type="textarea" 
						x={x}   
						wafId={wafId} 
						id="system-info" 
						title="System-Informationen: " 
						readOnly={true}
					/>
			</Form>
		</Module>
	);

}

