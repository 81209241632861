import React from "react";

export default function Dialog(props) {

    const x = props.x;

	const reference = React.useRef(null);

	const [display, setDisplay] = React.useState((typeof props.display === 'undefined') ? 'd-none' : props.display);
	const [title, setTitle] = React.useState(props.title);
	const handleClose = (typeof props.handleClose === 'undefined') ? () => {} : props.handleClose;

	const modal = (typeof props.modal == 'undefined') ? false : props.modal;

	x.wad.init(props.id, {
		"makeDialog":makeDialog,
		"hide":hide,
		"show":show,
		"toggleDisplay":toggleDisplay,
		"setTitle":setTitle,
	});

	React.useEffect(() => {
		x.wad[props.id].onAfterRender();
		x.wad[props.id].onAfterRender = () => {};
	});

	function cancel() {
		hide(0);
	}

	function hide(result) {
		if (typeof x.wab.modal != 'undefined') x.wab.modal.hide();
		setDisplay('d-none');
		x.wad.setDisplay(props.id, false);
		x.wad[props.id].onClose((result));
		handleClose(result);
	}

	function show(onAfterRender) {
		if (modal) {
			x.wab.modal.show();
		}
		if (typeof onAfterRender != 'undefined') {
			x.wad[props.id].onAfterRender = onAfterRender;
		}
		setDisplay('d-block');
		x.wad.setDisplay(props.id, true);
	}

	function toggleDisplay(onAfterRender) {
		if (typeof onAfterRender != 'undefined') {
			x.wad[props.id].onAfterRender = onAfterRender;
		}
		setDisplay(prev => (prev === 'd-block') ? 'd-none' : 'd-block');
		x.wad.toggleDisplay(props.id);
		if (modal) {
			x.wab.modal.toggleDisplay();
		}
	}

	function makeTitle() {
		let titleTag = null;
		let closeIcon = <></>;
		if (x.wau.getProperty(props.closeIcon, true)) {
			closeIcon = <i className="close-dialog bi bi-x-circle" onClick={e => cancel()}></i>
		}
		(typeof title === 'object') 
			?
				titleTag = 	<span className="title">
								{title}
							</span>
			:
				titleTag = 	<span className="title" dangerouslySetInnerHTML={{ __html: title }}/>


		let tag =
			<React.Fragment>
				{titleTag}
				{closeIcon}
			</React.Fragment>
		return tag;

	}

	function makeDialog() {
		let content = 
			<React.Fragment key="dialog-fragment">
				<div key="dialog-title" className='dialog-title'>
					{makeTitle()}
				</div>
				<div key="dialog-content" className='dialog-content'>
					{props.children}
				</div>
			</React.Fragment>
		return content;
	}


	return (
		<div 	id={props.id} key={"dialog-" + props.id} 
				ref={reference} 
				className={`dialog  ${props.classes} ${display}`} >
			{makeDialog()}
		</div>
	);

}
