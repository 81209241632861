import React from "react";
import Form from "Components/Core/Form/Form";
import FormElement from "Components/Core/Form/FormElement";
import PostingList from "./PostingList";
import 'CSS/Forum/PostingSearch.css';

export default function PostingSearch(props) {

    const x = props.x;
	const [postingCount, setPostingCount] = React.useState(-2);       	// Enthält die Anzahl der gefundenen Beiträge 
	const waf =  x.waf;
	const wafId = props.dialogId;
	const formId = props.dialogId + '-form';

	const postingList = [];										// Hier werden Zeiger auf die benötigten Funktionen der PostingList-Komponenten gespeichert

	x.wam.init(props.moduleId, {
		"onClose":() => {
			delete x.waf[wafId];
		}
	})


	React.useEffect(() => {
		x.waf.set(wafId, 'search', '**');
		x.waf.focus(wafId, 'search');
		x.waf.setCursorPos(wafId, 'search', 1,1);
	} , [waf, wafId, x]);

	function onAfterReadPostings(result) {
		setPostingCount(result.data['content'].length);
	}

	function searchPosting(event) {
		event.preventDefault();
		setPostingCount(-1); // -1 = Suche wird ausgeführt
		let addUrl = '&search=' + x.waf.get(wafId, 'search');
		addUrl += '&forumid=' + props.forumId;
		postingList.addSearchUrl(addUrl) ;
		postingList.searchPostings();
	}

	function newSearch() {
		setPostingCount(-2);
		x.waf[wafId].setPostingSource([]);
		x.waf.set(wafId, 'search', '**');
		x.waf.focus(wafId, 'search');
		x.waf.setCursorPos(wafId, 'search', 1,1);
	}

	function makeStatus(postingCount) {
		let searchStatus = null;
		let maxMessage = '';
		switch (postingCount) {
			case -2:
				searchStatus = <span></span>
				break;
			case -1:
				searchStatus = <span>Suche wird ausgeführt...</span>
				break;
			default:
				maxMessage = (postingCount === 200) ? '(Maximum von 200 erreicht)' : ''
				searchStatus =
					<span>
						<i id="new-search" className="bi bi-x-circle module-icon module-icon-clicked" onClick={e => newSearch()}>
							<span id="posting-count">{postingCount} {'Treffer' + maxMessage}</span>
						</i>
					</span>

		}

		return (
			<div key="search-status" id="search-status" className="search-status" >
				{searchStatus}
			</div>
		)
	}

	return (
		<div className="div-search-posting">
			<Form id={formId}
				className="form-search-posting"
				x={x}  
				wafId={wafId}
				sendButton={false}
				cancelButton={false}
				onSubmit={e => searchPosting(e)}
				dialogId={props.dialogId}
				sendButtonTitle="Suchen"
				autocomplete="off">
				<FormElement type="search" x={x}   wafId={wafId} id={'search'} dialogId={props.dialogId} title="Suche nach Titel:" maxLength="150" />
			</Form>
			<div className="div-status">
				{makeStatus(postingCount)}
			</div>
			<PostingList
					id="search-postings"
					x={x}  
					forumId={props.forumId}
					moduleId={props.moduleId}
					dialogId={props.dialogId}
					functionName="searchPostings"
					onRendered={(functions) => {
									postingList.addSearchUrl = functions.addSearchUrl;
									postingList.searchPostings = functions.searchPostings;
								}}
					module="Posting"
					task="PostingSearch"
					showDialogAnswer={props.showDialogAnswer}
					onAfterReadPostings={onAfterReadPostings}
					copyToClipboard={props.copyToClipboard}
			/>
		</div>
	);


}
