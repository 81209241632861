import React from 'react';
import Dialog from "Components/Core/Dialog";
import DialogAddLink from './DialogAddLink';
import DialogAddText from './DialogAddText';
import DialogNewImage from './DialogNewImage';
import FormSelectIcon from 'Components/Core/Form/FormSelectIcon';
import FormSelectText from 'Components/Core/Form/FormSelectText';
import DialogSelectText from 'Components/Core/Form/DialogSelectText';
import Module from 'Components/Core/Module';
import NewAnswer from './NewAnswer';
import NewThread from './NewThread';
import PostingDay from './PostingDay';
import PostingMember from './PostingMember';
import PostingList from './PostingList';
import Postings from './Postings';
import PostingSearch from './PostingSearch';
import Tab from 'Components/Core/Tabs';
import 'CSS/Forum/Forum.css'
import 'CSS/Forum/Postings.css'

export default function Forum(props) {

    const x = props.x;

	// IDs der integrierten Dialoge
	const answerId = props.id + '-answer';
	const newThreadId = props.id + '-new-thread';
	const searchId = props.id + '-search';
	const memberId = props.id + '-member';
	const currentId = props.id + '-current';
	const favoritsId = props.id + '-favorits';
	const postingDayId = props.id + '-posting-day';
	const postingMemberId = props.id + '-select-member-postings';
	const selectTopicId = props.id + '-select-topic';
	const dialogIds = [answerId, newThreadId, searchId, currentId, favoritsId, postingDayId];
	const newImageId = props.id + '-new-image';
	const layoutSettingsId = props.id + '-select-layout-settings';
	const closeButton = (props.id === 'forum') ? false : true;
	// const [title, setTitle] = React.useState(props.title);
	const title = props.title;
	const [answerTo, setAnswerTo] = React.useState(0);
	const [hideContent, setHideContent] = React.useState(x.was.forumHideContent);
	const [hideDiscussion, setHideDiscussion] = React.useState(x.was.forumHideDiscussion);
	const [hideImages, setHideImages] = React.useState(x.was.forumHideImages);
	const [hideAvatar, setHideAvatar] = React.useState(x.was.forumHideAvatar);
	const [hideDate, setHideDate] = React.useState(x.was.forumHideDate);
	const [memberColored, setMemberColored] = React.useState(x.was.forumMemberColored);
	const [fontBold, setFontBold] = React.useState(x.was.forumFontBold);
	const [forumScroll, setForumScroll] = React.useState(x.was.forumScroll);
	const [tabSelected, setTabSelected] = React.useState('postings');

	const renderCount = React.useRef(1);
	const postingCount = React.useRef(null);

	const postingListCurrent = [];										// Hier werden Zeiger auf die benötigten Funktionen der PostingList-Komponenten gespeichert
	const postingListFavorit = [];										// Hier werden Zeiger auf die benötigten Funktionen der PostingList-Komponenten gespeichert

	let updateLastPostingNoRead = (value) => { };

	React.useEffect(() => {
		if (renderCount.current === 1) {
			window.webapp.system.webAppLoaded();
			if (props.id === 'forum') x.wam[props.id].show();
		}
	}, [props.id, x.wam]);

	React.useEffect(() => {
		x.was.addWatchKey(props.id, (event) => {
			if (event.keyCode === 27) {
				x.wad.hideAll();
				//closeDialogs();
			}
		});
		if (renderCount.current === 1) {
			x.wam[props.id].postings.load();
			// x.wam[props.id].show();
		}
		// if (!x.was.isMobileDevice()) x.waf.focus('new-thread-short', 'new-posting-short');
		renderCount.current++;
	});

	React.useEffect(() => {
		return () => {
			x.was.delWatchKey(props.id);
		}

	}, [props.forums, props.id, x]);

	function postingsUnreadReset(loadPostings = false) {
		closeDialogs();
		const url = 'Backend/webapp.php?module=Posting&task=PostingsUnreadReset&forumId=' + props.forumId + '&postingCount=' + postingCount.current;
		x.was.axGet(url, (result) => {
			if (result.data.returncode === 1) {
				x.was.postingsUnread[props.id] = 0;
				x.was.postingsLastUnread[props.id] = 0;
				x.was.loadActiveMember();
				x.was.send('read-posting', { "memberid": x.was.memberId, "module": props.id });
				updateLastPostingNoRead(result.data.content['posting-last-no']);
				x.was.updatePostingsUnread();
				if (loadPostings) x.wam[props.id].postings.load();
			}
		});
	}

	/* Funktionen der Dialoge
		Über die Objekte "newThread", "newAnswer", "posting" und "search" stehen die dortigen Funktionen extern zur Verfügung.  
	*/
	if (typeof x.wam[props.id].newPostings == 'undefined') {
		x.wam.init(props.id, {
			"init": init,
			"newThread": {},
			"newAnswer": {},
			"postings": {},
			"current": {},
			"archive": {},
			"search": {},
			"newPostings": false,
			"lastPostingNo": 0,
			"quote": '',
			"quotePosting": 0,
			"getCurrentPostingCount": getCurrentPostingCount,
			"getCurrentTopicId": getCurrentTopicId,
			"moduleType": "forum",
			"title": props.title,
			"scrollTop": scrollTop,
			"debug": debug,
			"closeDialogs": closeDialogs,
			"onDialogClose": onDialogClose,
			"showDialogAnswer": showDialogAnswer,
			"showDialogNewThread": showDialogNewThread,
			"showPostings": showPostings,
			"showArchive": showArchive,
			"showMemberPostings": showMemberPostings,

		});
	}


	x.wad.init(answerId);
	x.wad.init(newThreadId);
	x.wad.init(searchId);

	// Tab Definition

	const forumTabs = {};
	forumTabs.postings = {
		"title": "Aktuell",
		"selected": true,
		"classes": "",
		"click": showPostings
	};
	forumTabs.archive = {
		"title": "Archiv",
		"selected": false,
		"classes": "",
		"click": showArchive
	};

	function init() {
		if (x.wam[props.id].newPostings) {
			x.wam[props.id].postings.load();
			x.wam[props.id].newPostings = false;
		}
		const conversationalPartner = x.wau.getConversationalPartner(props.id);
		if (typeof x.was.postingsUnreadHint[conversationalPartner] !== 'undefined' && x.wah.isVisible(x.was.postingsUnreadHint[conversationalPartner])) {
			x.wah.removeHint(null, x.was.postingsUnreadHint[conversationalPartner]);
		}
	}

	function debug() {
		const module = props.id + ' - ';
		console.log(module);
	}

	function setLayoutSettingsData() {
		const layoutSettings = [];
		const keys = ['scroll', 'images', 'content', 'discussion', 'avatar', 'date', 'memberColored', 'font'];
		let id = 0;
		(forumScroll === 1) ? addData('Horizontale Ansicht umbrechen') : addData('Horizontale Ansicht scrollen');
		(hideImages === 1) ? addData('Bilder anzeigen') : addData('Bilder ausblenden');
		(hideContent === 1) ? addData('Inhalte anzeigen') : addData('Inhalte ausblenden');
		(hideDiscussion === 1) ? addData('Unterhaltung anzeigen') : addData('Unterhaltung ausblenden');
		(hideAvatar === 1) ? addData('Avatar anzeigen') : addData('Avatar ausblenden');
		(hideDate === 1) ? addData('Datum anzeigen') : addData('Datum ausblenden');
		(memberColored === 1) ? addData('Name des Verfassers schwarz anzeigen') : addData('Name des Verfassers farbig anzeigen');
		(fontBold === 1) ? addData('Normale Schrift') : addData('Fette Schrift');

		return layoutSettings;

		function addData(name) {
			layoutSettings.push({ "id": keys[id], "name": name });
			id++;
		}

	}

	function postingQuote(event, posting, postdate, membername) {
		event.preventDefault();
		const quote = makeQuote(posting, postdate, membername);
		showDialogNewThread(quote, 0);
		// console.log(quote);
	}

	async function copyToClipboard(event, posting, postdate, membername) {
		event.preventDefault();
		try {
			await navigator.clipboard.writeText(makeQuote(posting, postdate, membername));
			event.preventDefault();
			x.wah.addHint('Der Beitrag wurde in die Zwischenablage kopiert...', true);
		} catch (error) {
			console.error(error.message);
		}
	}

	function makeQuote(posting, postdate, membername) {
		const quote = '' + String.fromCharCode(10) + '[quote]' + membername + ' ' + postdate + String.fromCharCode(10) + posting + String.fromCharCode(10) + '[/quote]' + String.fromCharCode(10) 
		console.log(membername);
		return quote;
	}

	function closeDialogs(currentDialog = '') {
		dialogIds.forEach(
			function (dialog) {
				if (dialog !== currentDialog) {
					x.wad[dialog].hide();
				}
			}
		);
	}

	function getCurrentPostingCount() {
		x.wam[props.id].currentPostingNo = (x.wam[props.id].currentPostingNo === 0) ? (x.wam[props.id].lastPostingNo - 1) : x.wam[props.id].currentPostingNo;
		return x.wam[props.id].lastPostingNo - x.wam[props.id].currentPostingNo;
	}

	function onDialogClose() {
		setTabSelected('postings');
		console.log('onDialogClose');
	}

	function showPostings() {
		setTabSelected('postings');
	}

	function showArchive() {
		setTabSelected('archive');
	}

	function toggleView() {
		setHideContent(prev => (prev === 1) ? 0 : 1);
		// x.wam[props.id].postings.render();
	}

	function toggleDiscussion() {
		setHideDiscussion(prev => (prev === 1) ? 0 : 1);
	}

	function toggleImages() {
		setHideImages(prev => (prev === 1) ? 0 : 1);
	}

	function toggleAvatar() {
		setHideAvatar(prev => (prev === 1) ? 0 : 1);
	}

	function toggleScroll() {
		setForumScroll(prev => (prev === 1) ? 0 : 1);
	}

	function toggleDate() {
		setHideDate(prev => (prev === 1) ? 0 : 1);
	}

	function toggleMemberColored() {
		setMemberColored(prev => (prev === 1) ? 0 : 1);
	}

	function toggleFont() {
		setFontBold(prev => (prev === 1) ? 0 : 1);
	}


	function toggleCurrent() {
		closeDialogs(currentId);
		x.wad[currentId].toggleDisplay();
		if (x.wad[currentId].isVisible) {
			// x.wam[props.id].loadCurrentPostings()
			postingListCurrent.load();
		}
	}

	function toggleFavorits() {
		closeDialogs(favoritsId);
		x.wad[favoritsId].toggleDisplay();
		if (x.wad[favoritsId].isVisible) {
			postingListFavorit.load();
		}
	}

	function toggleMember() {
		closeDialogs(memberId);
		x.wad[memberId].toggleDisplay();
	}

	function toggleSearch() {
		x.wad.hideAll(searchId);
		x.wad[searchId].toggleDisplay(() => { x.waf.focus(searchId, 'search') });
	}

	function togglePostingDay() {
		closeDialogs(postingDayId);
		x.wad[postingDayId].toggleDisplay();
	}

	function showDialogNewThread(quote = '', curPos = -1) {
		closeDialogs();
		// Neuer Thread-Dialog anzeigen
		x.wad[answerId].hide();
		if (quote !== '') {
			x.waf.set(newThreadId, 'posting', quote);
			x.wau.postingBackup(newThreadId, 'posting', quote);
		}
		x.wad[newThreadId].init();
		x.wad[newThreadId].show(() => { x.waf.focus(newThreadId, 'posting') });
		if (curPos > -1) {
			x.waf.setCursorPos(newThreadId, 'posting', curPos, curPos);
		}
		setTabSelected('postings');
	}

	function showDialogAnswer(reloadPostings, topicId, topicName, postingNo, visible, posting, memberId, memberName, postingDate) {
		closeDialogs();
		x.wau.postingRestore(answerId, postingNo);
		x.waf[answerId].addSend['answerto'] = postingNo;
		x.waf.set(answerId, 'base-posting', posting);
		setAnswerTo(postingNo);
		x.wad[answerId].memberId = memberId;
		x.wad[answerId].memberName = memberName;
		x.wad[answerId].postingNo = postingNo;
		x.wad[answerId].topicId = topicId;
		x.wad[answerId].topicName = topicName;
		x.wad[answerId].postingDate = postingDate;
		x.wad[answerId].posting = posting;
		x.wad[answerId].visible = visible;
		// x.waf.set(props.id + '-form-current-topic', props.id + '-select-set-topic' + '-form', topicName);
		x.waf.set(props.id + '-form-current-topic', `${props.id}-select-set-topic-form`, topicName);
		x.wad[answerId].init(postingNo, visible, memberId); // Wird in PostingContextMenu.js ausgeführt
		// x.wad[answerId].postingRestore();

		x.wad[answerId].show(() => {
			x.waf.focus(answerId, 'posting');
			// x.wad[answerId].postingRestore();
		});
		x.wad[answerId].reloadPostings = reloadPostings;
	}

	function showMemberPostings(memberId, memberName) {
		closeDialogs();
		toggleMember();
		x.wad[postingMemberId + '-dialog'].setValue(memberId, memberName);
	}

	function scrollTop() {
		x.$('#postings-' + props.id + '-postings').animate({ scrollTop: 0 }, 600);
		x.wam[props.id].postings.load();
		showPostings();
	}

	function getCurrentTopicId() {
		return x.waf.getKey('form-current-topic', selectTopicId + '-form');
	}

	function getTopicList() {
		const topicList = [{ 'id': 0, 'name': 'Kein Filter' }].concat(x.was.topics);
		return topicList;
	}

	const openProfileTag =
		(typeof props.memberName !== 'undefined' && props.memberName !== '') ?
			<span className={` bi bi-person-fill module-icon  `} title="Profil anzeigen" onClick={() => x.wam.openProfile(props.memberId, props.memberName, props.memberLevel)}>
			</span>
			:
			null

	const titleToolbarClass =
		(typeof props.memberName !== 'undefined' && props.memberName !== '') ?
			"module-title-toolbar-member"
			:
			"module-title-toolbar"


	const selectTopicTag =
		<FormSelectText
			id={selectTopicId}
			iconMode={false}
			wafId="form-current-topic"
			name="topicid"
			componentId={x.wau.getComponentId()}
			moduleId={props.id}
			x={x}  
			classes="form-select-topic"
			default=""
			defaultKey={0}
			title="Alle Themen"
			alwaysShowSelection={false}
			afterSelect={(key, value) => {
				x.wam[props.id].postings.load();

			}}
			resetIcon={false}
		/>


	return (

		<React.Fragment>
			<Module id={props.id}
				x={x}  
				title={title}
				classes={`module-forum `}
				display={props.display}
				displayTitle="d-block"
				titleToolbar=<span className={`${titleToolbarClass}`}>
					{openProfileTag}
					{selectTopicTag}
				</span>
				closeButton={closeButton}>
				<Tab
					id={props.id + '-tab'}
					x={x}  
					moduleId={props.id}
					tabs={forumTabs}
					tabSelected={tabSelected}
					tabClicked={setTabSelected}
					toolbar={
						<React.Fragment>
							{
								<span
									className={`   `}
									onClick={() => { postingsUnreadReset() }}
									title="Ungelesene Beiträge auf 0 stellen"
								>
									<span
										className={` bi bi-check2-square module-icon new-postings-icon `}

									/>
								</span>

							}
							<span className='module-icon-group' onClick={e => toggleCurrent()}>
								<span
									className={` bi bi-bell module-icon ${(x.was.postingsUnread[props.id] > 0) ? "module-icon-clicked" : ""} `}
									title={"Neueste Beiträge " + ((x.was.postingsUnread[props.id] > 0) ? "(" + x.was.postingsUnread[props.id] + " ungelesen)" : "")} >
								</span>
							</span>
							<span className={` bi bi-star-fill module-icon `}
								title="Favoriten anzeigen"
								onClick={e => toggleFavorits()}>
							</span>
							<span className={` bi bi-align-top module-icon `}
								title="Zum Anfang der Beiträge scrollen"
								onClick={e => scrollTop()}>
							</span>
							<span className={` bi bi-search module-icon `}
								title="Suchen"
								onClick={e => toggleSearch()}>
							</span>
							<span className={` bi bi-calendar-date module-icon `}
								title="Einen gesamten Tag anzeigen"
								onClick={e => togglePostingDay()}>
							</span>
							<span className={` bi bi-person module-icon `}
								title="Beiträge eines Mitglieds anzeigen"
								onClick={e => toggleMember()}>
							</span>

							<FormSelectIcon
								id={layoutSettingsId}
								wafId={layoutSettingsId}
								componentId={x.wau.getComponentId()}
								moduleId={props.id}
								x={x}  
								classes="bi bi-gear-wide module-icon"
								default=""
								defaultKey={0}
								title='Layout-Einstellungen'
								hiddenInput={true}
								inputIcon='bi-gear-wide'
								onSelect={(key, value) => {
									// ['scroll', 'images', 'content', 'discussion', 'avatar', 'date', 'time']
									switch (key) {
										case 'scroll':
											toggleScroll();
											break;
										case 'images':
											toggleImages();
											break;
										case 'content':
											toggleView();
											break;
										case 'discussion':
											toggleDiscussion();
											break;
										case 'avatar':
											toggleAvatar();
											break;
										case 'date':
											toggleDate();
											break;
										case 'memberColored':
											toggleMemberColored();
											break;
										case 'font':
											toggleFont();
											break;
										default:
											break;
									}
								}}
							/>
						</React.Fragment>

					}
					updateForum={props.updateForum}
				/>
				<Postings
					id={'postings-' + props.id}
					threadMode={false}
					forumId={props.forumId}
					x={x}  
					classes={` ${(tabSelected === 'postings') ? "d-block" : "d-none"}`}
					hideContent={hideContent}
					hideDiscussion={hideDiscussion}
					hideImages={hideImages}
					hideAvatar={hideAvatar}
					hideDate={hideDate}
					memberColored={memberColored}
					fontBold={fontBold}
					forumScroll={forumScroll}
					showDialogAnswer={showDialogAnswer}
					moduleId={props.id}
					postingQuote={postingQuote}
					copyToClipboard={copyToClipboard}
					onRendered={(functions) => {
						x.wam[props.id].postings.load = functions.load;
						x.wam[props.id].postings.render = functions.makePostings;
						x.wam[props.id].postings.setSelectDate = functions.setSelectDate;
						x.wam[props.id].postings.setSelectThread = functions.setSelectThread;
						updateLastPostingNoRead = functions.updateLastPostingNoRead;
						x.wam[props.id].reload = () => {
							functions.load();
							// closeDialogs();
							// showPostings();
						};
					}}
					onLoaded={(postingInfo) => {
						postingCount.current = postingInfo.postingCount;
					}}
				/>
				<Postings
					id={'archive-' + props.id}
					threadMode={true}
					forumId={props.forumId}
					x={x}  
					classes={` ${(tabSelected === 'archive') ? "d-block" : "d-none"} `}
					hideContent={hideContent}
					hideDiscussion={hideDiscussion}
					hideImages={hideImages}
					hideAvatar={hideAvatar}
					hideDate={hideDate}
					memberColored={memberColored}
					fontBold={fontBold}
					forumScroll={forumScroll}
					showDialogAnswer={showDialogAnswer}
					moduleId={props.id}
					postingQuote={postingQuote}
					copyToClipboard={copyToClipboard}
					onRendered={(functions) => {
						x.wam[props.id].archive.load = functions.load;
						x.wam[props.id].archive.setSelectDate = functions.setSelectDate;
						x.wam[props.id].archive.setSelectThread = functions.setSelectThread;
					}}

				/>

				<Dialog id={answerId} x={x}   classes="dialog-new-posting dialog-new-answer " title="Beitrag bearbeiten/antworten" >
					<NewAnswer
						x={x}  
						forumId={props.forumId}
						newPostingFunctions={x.wam[props.id].newAnswer}
						moduleId={props.id}
						dialogId={answerId}
						answerTo={answerTo}
						postingQuote={postingQuote}
						copyToClipboard={copyToClipboard}
					/>
				</Dialog>

				<Dialog id={newThreadId} x={x}   classes="dialog-new-posting dialog-new-thread" title="Neues Thema" >
					<NewThread
						x={x}  
						forumId={props.forumId}
						newPostingFunctions={x.wam[props.id].newThread}
						moduleId={props.id}
						dialogId={newThreadId}
						answerTo={0}
						postingQuote={postingQuote}
					/>
				</Dialog>
				<DialogNewImage
					id={newImageId}
					x={x}  
					moduleId={props.id}
					dialogId={newImageId}
				/>
				<Dialog id={currentId} x={x}   classes="dialog-postings-list " title="Neueste Beiträge" >
					<PostingList
						id="current-postings"
						x={x}  
						forumId={props.forumId}
						moduleId={props.id}
						dialogId={currentId}
						module="Posting"
						task="PostingCurrent"
						postingQuote={postingQuote}
						copyToClipboard={copyToClipboard}
						showDialogAnswer={showDialogAnswer}
						onRendered={(functions) => {
							postingListCurrent.load = functions.searchPostings;
						}}

					/>
				</Dialog>
				<Dialog id={favoritsId} x={x}   classes="dialog-postings-list " title="Favoriten" >
					<PostingList
						id={favoritsId + "-listing"}
						x={x}  
						forumId={props.forumId}
						moduleId={props.id}
						dialogId={favoritsId}
						module="Posting"
						task="PostingFavorits"
						postingQuote={postingQuote}
						copyToClipboard={copyToClipboard}
						showDialogAnswer={showDialogAnswer}
						onRendered={(functions) => {
							postingListFavorit.load = functions.searchPostings;
						}}

					/>
				</Dialog>
				<Dialog id={searchId} x={x}   classes="dialog-search-posting " title="Suchen" >
					<PostingSearch
						x={x} 
						 
						forumId={props.forumId}
						moduleId={props.id}
						dialogId={searchId}
						copyToClipboard={copyToClipboard}
						showDialogAnswer={showDialogAnswer}
					/>
				</Dialog>

				<PostingMember
					x={x}  
					forumId={props.forumId}
					moduleId={props.id}
					postingMemberId={postingMemberId}
					toggleMember={toggleMember}
					showDialogAnswer={showDialogAnswer}
					copyToClipboard={copyToClipboard}
				/>
				<Dialog id={postingDayId} x={x}   classes="dialog-posting-day " title="Gesamten Tag anzeigen" >
					<PostingDay
						x={x}  
						forumId={props.forumId}
						moduleId={props.id}
						dialogId={postingDayId}
					/>
				</Dialog>
				<DialogAddLink id={props.id + '-add-link'} x={x}   title="Link einfügen" answerTo={answerTo} />
				<DialogAddText id={props.id + '-add-list'} x={x}   tag="ul" type="textarea" title="Liste einfügen" answerTo={answerTo} />
				<DialogAddText id={props.id + '-add-text-b'} x={x}   tag="b" title="Fett formatierter Text" answerTo={answerTo} />
				<DialogAddText id={props.id + '-add-text-i'} x={x}   tag="i" title="Kursiv formatierter Text" answerTo={answerTo} />
				<DialogAddText id={props.id + '-add-text-u'} x={x}   tag="u" title="Unterstrichener Text" answerTo={answerTo} />
				<DialogAddText id={props.id + '-add-text-quote'} x={x}   tag="quote" title="Zitat" answerTo={answerTo} />
				<DialogSelectText
					id={layoutSettingsId}
					moduleId={props.id}
					x={x}  
					onSetData={setLayoutSettingsData}
					classes="dialog-select-layout-settings"
					title="Layout-Optionen"
				/>

				<DialogSelectText id={selectTopicId} moduleId={props.id} x={x}   onSetData={getTopicList} title="Nach Thema filtern" />

			</Module>
		</React.Fragment>
	)
};
