import React from 'react';
import 'CSS/Listing.css';

export default function Listing(props) {

    const x = props.x;

	var row = [];
	var rowCount = 0;
	var rowHeader = [];
	var rowFooter = [];
	var rowHeaderCount = 0;
	var rowFooterCount = 0;
	var headerTag;
	var footerTag;
	let header = false;
	let footer = false;

	const [content, setContent] = React.useState('');
	const [display, setDisplay] = React.useState('d-none');

	const withDeleteRow = (typeof props.withDeleteRow === 'undefined') ? false : props.withDeleteRow;
	const onDeleteClick = (typeof props.onDeleteClick === 'undefined') ? () => {} : props.onDeleteClick;

	props.registerListing({
		"addCloseButton": addCloseButton,
		"addRow": addRow,
		"addHeader": addHeader,
		"addFooter": addFooter,
		"hide": hide,
		"makeListing": makeListing,
		"reset": reset,
		"show": show,
	});

	function show() {
		setDisplay('d-block');
	}


	function hide() {
		setDisplay('d-none');
	}

	function addCloseButton(id) {
		addHeader(<i className="bi bi-x-lg listing-icon" onClick={e => x.wab[id].hide()}></i>, 'listing-close-button');
	}

	function addHeader(content, columnClass) {
		header=true;
		rowHeaderCount++
		if (typeof columnClass == 'undefined') {
			columnClass = '';
		}
		rowHeader.push(	
						<span 
							key={rowHeaderCount} 
							className={`column header-column ${columnClass}  ${(rowHeaderCount>1) ? 'multicolumn':''} header-column-${rowHeaderCount}`}
						>
							{content}
						</span>);
	}


	function addRow(columns, rowFields, classes, onRowClick = () => {}) {
		const columnTags = [];
		let nextKey = 1;
		columns.forEach(column => {
			columnTags.push(
				<span 
					key={nextKey} 
					className={`column column-${nextKey} ${(nextKey>1) ? 'multicolumn':''}`} 
					title={(typeof column === 'object') ? '' : column}
				>
					{column}
				</span>);
			nextKey++;
		});
		if (withDeleteRow) {
			columnTags.push(
				<span 
					key={nextKey} 
					className={`column column-${nextKey} ${(nextKey>1) ? 'multicolumn':''}`} 
				>
					<i 
						className="bi bi-trash3" 
						deleterow="1"
					/>
				</span>);
		}
		const className = (typeof classes == 'undefined') ? '' : classes;
		row.push(
			<div 
				key={"listing-row-" + rowCount} 
				onClick={(e) => rowClicked(e, rowFields, onRowClick)} 
				className={`listing-row ${className}`}
			>
				{columnTags}
			</div>)
		rowCount++;
	}

	function addFooter(content, columnClass) {
		footer=true;
		rowFooterCount++
		if (typeof columnClass == 'undefined') {
			columnClass = '';
		}
		rowFooter.push(	<span 
							key={rowFooterCount} 
							className={`column footer-column ${columnClass}  ${(rowFooterCount>1) ? 'multicolumn':''} footer-column-${rowFooterCount}`}
						>
							{content}
						</span>);
	}

	function makeListing(showListing = true) {
		makeHeaderTag();
		makeFooterTag();
		setContent(
			<React.Fragment key="listing-fragment">
				<div key="listing-header" className='listing-header'>
					{headerTag}
				</div>
				<div key="listing-content" className='listing-content'>
					{row}
				</div>
				{footerTag}
			</React.Fragment>
		)
		if (showListing) show();
	}

	function makeHeaderTag() {
		const deleteTag = (withDeleteRow) ? <i className="bi bi-trash3" onClick={(e) => onDeleteClick(e, null)}></i> : null;
		addHeader(deleteTag);
		if (header) {
			headerTag =
				<div key="listing-header-row" className={` listing-header-row  ${(typeof props.headerClass == 'undefined') ? '': props.HeaderClass} ${(rowHeaderCount<2) ? 'listing-header-center': 'listing-header-left'} `}>
					{rowHeader}
				</div>
		}
		else {
			headerTag = null;
		}
	}

	function makeFooterTag() {
		if (footer) {
			footerTag =
				<div key="listing-footer" className={` listing-footer ${(typeof props.footerClass == 'undefined') ? '': props.footerClass}  `}>
					{rowFooter}
				</div>
		}
		else {
			footerTag = null;
		}
	}

	function reset() {
		rowHeader = [];
		rowFooter = [];
		header = false;
	    footer = false;
		rowHeaderCount = 0;
		rowFooterCount = 0;
		row = [];
		rowCount = 0;
	}

	function rowClicked(e, rowFields, onRowClick) {
		if (e.target.getAttribute("deleterow") === '1') {
			onDeleteClick(e, rowFields);
			return;
		}
		onRowClick(e, rowFields);
	}

	return (
		<div id={props.id} key="listing" className={`listing-basis ${props.classes} ${display}`} >
			{content}
		</div>
	);

}
