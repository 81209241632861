import React from 'react';
import axios from "axios";

import Content from './Content';

import WebappSystem from 'Components/Webapp/WebappSystem';
import WebappBlock from 'Components/Webapp/WebappBlock';
import WebappDialog from 'Components/Webapp/WebappDialog';
import WebappForm from 'Components/Webapp/WebappForm';
import WebappHint from 'Components/Webapp/WebappHint';
import WebappLayout from 'Components/Webapp/WebappLayout';
import WebappModule from 'Components/Webapp/WebappModule';
import WebappNavigation from 'Components/Webapp/WebappNavigation';
import WebappUtility from 'Components/Webapp/WebappUtility';
// import WebappWebSocket from 'Components/Webapp/WebappWebSocket';

export default function Webapp(props) {

	const renderChildCount = React.useRef(0);
	const initData = React.useRef(null);

	const [login, setLogin] = React.useState(false);
	const [style, setStyle] = React.useState({});
	const [webapp, setWebapp] = React.useState([]);

	const [renderForum, setRenderForum] = React.useState(Date.now());

	const wa = React.useRef({});
	window.webapp = wa.current;
	wa.current.block = (typeof wa.current.block === 'undefined') ? {} : wa.current.block;
	wa.current.block.tags = (typeof wa.current.block.tags === 'undefined') ? [] : wa.current.block.tags;
	wa.current.dialog = (typeof wa.current.dialog === 'undefined') ? {} : wa.current.dialog;
	wa.current.dialog.tags = (typeof wa.current.dialog.tags === 'undefined') ? [] : wa.current.dialog.tags;
	wa.current.form = (typeof wa.current.form === 'undefined') ? {} : wa.current.form;
	wa.current.layout = (typeof wa.current.layout === 'undefined') ? {} : wa.current.layout;
	wa.current.hint = (typeof wa.current.hint === 'undefined') ? {} : wa.current.hint;
	wa.current.module = (typeof wa.current.module === 'undefined') ? {} : wa.current.module;
	wa.current.navigation = (typeof wa.current.navigation === 'undefined') ? {} : wa.current.navigation;
	wa.current.system = (typeof wa.current.system === 'undefined') ? {} : wa.current.system;
	wa.current.utility = (typeof wa.current.utility === 'undefined') ? {} : wa.current.utility;
//	wa.current.webSocket = (typeof wa.current.webSocket === 'undefined') ? {} : wa.current.webSocket;

	const x = {
		"$": window.$,
		"wab": wa.current.block,
		"wad": wa.current.dialog,
		"waf": wa.current.form,
		"wah": wa.current.hint,
		"wal": wa.current.layout,
		"wam": wa.current.module,
		"wan": wa.current.navigation,
		"was": wa.current.system,
		"wau": wa.current.utility,
		"waw": wa.current.webSocket,
	};
	const renderCount = React.useRef(1);

	x.wal.style = (typeof x.wal.style === 'undefined') ? {} : x.wal.style;

	React.useEffect(() => {
		document.addEventListener("keyup", onKey);
		document.addEventListener("click", onClick);
		if (renderCount.current === 1) {
			const getInit = async () => {
				const token = localStorage.getItem("token");
				let url = '/Backend/webapp.php?module=Public&task=Init&token=' + ((token === null) ? '0' : token);
				initData.current = await axios.get(url);
				// console.log(initData.current.data.content.smilies);
				createWebapp();
			};
			getInit();

			renderCount.current++;
		}
		return () => {
			document.removeEventListener("keyup", onKey);
		}
	});

	function createWebapp() {
		addWebapp(<WebappLayout id="webapp-layout" x={x} wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappUtility id="webapp-utility" x={x} wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappSystem id="webapp-system" x={x} wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappNavigation id="webapp-navigation" x={x} wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappModule id="webapp-module" x={x} wa={wa.current} childRendered={childRendered} />);
//		addWebapp(<WebappWebSocket id="webapp-socket" x={x} wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappBlock id="webapp-block" x={x} wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappDialog id="webapp-dialog" x={x} wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappForm id="webapp-form" x={x} wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappHint id="webapp-hint" x={x} wa={wa.current} childRendered={childRendered} />);
	}

	function childRendered(id = '') {
		renderChildCount.current++;
		if (id === 'webapp-layout') {
			setStyle(x.wal.style);
		}
		if (renderChildCount.current === 9) {
			webappRendered();
		}
	}


	function webappRendered() {

		const result = initData.current;

		if (result.data['returncode'] === 1) {
			if (document.body.clientWidth < 350) {
				// window.location.href = 'bad-device.html';
			}
			// registriere globale Funktionen
			x.was.activateNavigation = activateNavigation;
			x.was.renderForum = updateForum;
			// console.log(token);
			// System-Variable setzen
			x.was.buildNo = result.data.content.labels.buildno;
			x.was.setSmilies(result.data.content.smilies);
			x.was.memberId = result.data.content.memberid;
			x.was.memberName = result.data.content.membername;
			x.was.avatarText = result.data.content.avatartext;
			x.was.memberLevel = result.data.content.memberlevel;
			x.was.forumScroll = (result.data.content.forumscroll);
			x.was.forumHideImages = result.data.content.forumhideimages;
			x.was.forumHideContent = result.data.content.forumhidecontent;
			x.was.forumHideDiscussion = result.data.content.forumhidediscussion;
			x.was.forumHideAvatar = result.data.content.forumhideavatar;
			x.was.forumHideDate = result.data.content.forumhidedate;
			x.was.forumMemberColored = result.data.content.forummembercolored;
			x.was.forumFontBold = result.data.content.forumfontbold;
			x.was.forumSendWithEnter = result.data.content.forumsendwithenter;
			x.was.darkTheme = result.data.content.darktheme;
			x.was.darkThemeVariant = result.data.content.darkthemevariant;
			x.was.webAppLoaded = webAppLoaded;
			x.wal.setStyle();
			setStyle(x.wal.style);
			// console.log('Build No: ' + x.was.buildNo);
			// x.was.updateUnread(JSON.parse(result.data.content.unread));
			setRenderForum(Date.now());
			x.was.postingsUnread = JSON.parse(result.data.content.unread);
			init(wa.current.system);

			x.was.application = result.data.content.labels.application;
			document.title = x.was.application;

			// setTheme('light');

		}
		else {
			x.was.application = result.data.content.labels.application;
			x.was.privateMessage = result.data.content.labels.privatemessage;
			setLogin(true);
		}
	};

	function webAppLoaded() {
		if (localStorage.getItem('software-update') !== null) {
			x.wah.addHint('Die Software wurde aktualisiert...', false);
			localStorage.removeItem('software-update');
		}
	}


	function init(was) {
		// Clipboard
		document.addEventListener('paste', function (evt) {
			// Get the data of clipboard
			const clipboardItems = evt.clipboardData.items;
			const items = [].slice.call(clipboardItems).filter(function (item) {
				// Filter the image items only
				return item.type.indexOf('image') !== -1;
			});
			if (items.length > 0) {
				const currentModuleId = wa.current.module.getForumId();
				wa.current.module.activate(currentModuleId);
				const item = items[0];
				const image = item.getAsFile();
				const newImageId = currentModuleId + '-new-image';
				wa.current.dialog[newImageId].setImage(image);
				wa.current.dialog[newImageId].activate();
			}
		});
	}

	function updateForum() {
		setRenderForum(Date.now());
	}

	const addWebapp = (obj) => {
		obj = { tag: obj };
		setWebapp(current => [...current, obj]);
	};

	function activateNavigation(id) {
		x.wan.hideAll();
		x.wan[id].init();
		x.wan[id].show();
	}

	function onKey(event) {
		wa.current.system.onKey(event);
	}
	function onClick(event) {
		wa.current.system.onClick(event);
	}

	return (
		<div id="webapp" key="webapp" style={style}>
			{webapp.map(({ tag, id }) =>
				<React.Fragment key={tag.props.id}> {tag}</React.Fragment>
			)}
			{(renderChildCount.current === 9) 
				? 
					<Content	
						x={x} 
						login={login}
						renderForum={renderForum}
						webAppReady={(renderChildCount.current === 9) ? true : false}
					/>
				: 
					null
			}
		</div>
	)

}