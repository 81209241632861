import React from "react";
import Module from 'Components/Core/Module';
import DialogChangePassword from "./DialogChangePassword";
import Form from "Components/Core/Form/Form";
import FormElement from '../Core/Form/FormElement';
import Avatar from './Avatar';
import 'CSS/Member/Member.css';

export default function Settings(props) {

	const x = props.x;
	const newAvatarImage = React.useRef(null);
	const [newAvatarUrl, setNewAvatarUrl] = React.useState('');
	const [memberAvatarImage, setMemberAvatarImage] = React.useState('');
	const [memberAvatarImageBackup, setMemberAvatarImageBackup] = React.useState('');
	const [memberLevel, setMemberLevel] = React.useState(4);
	const [memberName, setMemberName] = React.useState(" ");
	const [avatarImageSelected, setAvatarImageSelected] = React.useState(false);
	const [defaultAvatarSelected, setDefaultAvatarSelected] = React.useState(false);

	const wafId = props.id;
	const dialogPasswordId = 'dialog-change-password';
	const dialogAvatarId = 'dialog-set-avatar';

	x.waf.init(wafId, {
		"onAfterRead": onAfterRead,
		"onAfterSend": onAfterSend
	});

	x.wam.init(props.id, {
		"title": 'Einstellungen',
		"onClose": () => {
			x.waf.destroy(wafId);
			x.was.removeDialog(dialogAvatarId);
			x.was.removeDialog(dialogPasswordId);
			x.waf.destroy(dialogPasswordId);
		}
	});

	React.useEffect(() => {
		return () => {
			// x.waf.destroy(wafId);
		}
	}, [])

	function changePassword() {
		x.wad[dialogPasswordId].show(() => { x.waf.focus(dialogPasswordId, 'old-password') });
	}

	function createToken() {
		const hexDigits = "0123456789abcdef";
		const uuid = [];
	  
		for (let i = 0; i < 36; i++) {
		  if (i === 8 || i === 13 || i === 18 || i === 23) {
			uuid.push("-");
		  } else if (i === 14) {
			uuid.push("4"); // UUID version 4
		  } else if (i === 19) {
			const random = Math.random() * 16 | 0; // Get a random number between 0-15
			uuid.push(hexDigits[(random & 0x3) | 0x8]); // Ensure the first two bits are 10
		  } else {
			const random = Math.random() * 16 | 0;
			uuid.push(hexDigits[random]);
		  }
		}
	  
		const token = uuid.join("");		
		x.waf.set(wafId, 'pageStoreToken', token);
	}

	function onAfterRead(data) {
		setMemberName(data.content.membername);
		setMemberLevel(data.content.memberlevel);
		setMemberAvatarImage(data.content.avatarimage);
		setMemberAvatarImageBackup(data.content.avatarimage);
		x.waf.set(wafId, 'memberId', data.content.memberid, true);
		x.waf.set(wafId, 'memberLevel', x.was.memberLevelLabel[data.content.memberlevel], true);
		x.waf.set(wafId, 'memberName', data.content.membername, true);
		x.waf.set(wafId, 'name', data.content.name, true);
		x.waf.set(wafId, 'firstName', data.content.firstname, true);
		x.waf.set(wafId, 'mail', data.content.mail, true);
		x.waf.set(wafId, 'pageStoreToken', data.content.pagestoretoken, true);
		x.waf.set(wafId, 'forumScroll', data.content.forumscroll, true);
		x.waf.set(wafId, 'forumHideImages', data.content.forumhideimages, true);
		x.waf.set(wafId, 'forumHideContent', data.content.forumhidecontent, true);
		x.waf.set(wafId, 'forumHideDiscussion', data.content.forumhidediscussion, true);
		x.waf.set(wafId, 'forumHideAvatar', data.content.forumhideavatar, true);
		x.waf.set(wafId, 'forumHideDate', data.content.forumhidedate, true);
		x.waf.set(wafId, 'forumMemberColored', data.content.forummembercolored, true);
		x.waf.set(wafId, 'forumFontBold', data.content.forumfontbold, true);
		x.waf.set(wafId, 'forumSendWithEnter', data.content.forumsendwithenter, true);
		x.waf.set(wafId, 'darkTheme', data.content.darktheme, true);
		x.waf.set(wafId, 'darkThemeVariant', data.content.darkthemevariant, true);
		x.waf.set(wafId, 'postingCount', data.content.postingcount, true);
		x.waf.set(wafId, 'lastLogin', data.content.lastlogin, true);
		x.waf.set(wafId, 'lastRequest', data.content.lastrequest, true);
	}

	function onAfterSend(data) {

		x.was.forumHideImages = (x.waf.get(wafId, 'forumHideImages') === 1) ? 'hide-images' : '';
		x.was.forumHideContent = (x.waf.get(wafId, 'forumHideContent') === 1) ? 'd-none' : 'd-block';
		x.was.forumHideDiscussion = (x.waf.get(wafId, 'forumHideDiscussion') === 1) ? 'd-none' : 'd-block';
		x.was.forumHideOT = (x.waf.get(wafId, 'forumHideContent') === 1) ? 'd-none' : 'd-inline';
		x.was.forumHideAvatar = (x.waf.get(wafId, 'forumHideAvatar') === 1) ? true : false;
		x.was.forumHideDate = (x.waf.get(wafId, 'forumHideDate') === 1) ? 'd-none' : 'd-inline';
		x.was.darkTheme = (x.waf.get(wafId, 'darkTheme') === 1) ? true : false;
		x.was.darkThemeVariant = (x.waf.get(wafId, 'darkThemeVariant') === 1) ? true : false;
	}

	function setImage(e) {
		e.preventDefault();
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = ".png";
		input.onchange = _ => {
			const files = Array.from(input.files);
			newAvatarImage.current = files[0];
			const url = window.URL.createObjectURL(files[0])
			setNewAvatarUrl(url);
			setAvatarImageSelected(true);
		};
		input.click();
	}

	function setDefaultAvatar() {
		setMemberAvatarImage('');
		setDefaultAvatarSelected(true);
	}

	function saveAvatarImage() {
		const formData = new FormData();
		formData.append('image', newAvatarImage.current, 'avatar.png');
		x.was.axPost('Backend/webapp.php?module=Member&task=SaveAvatar', formData, (result) => {
			if (result.data.returncode === 1) {
				setAvatarImageSelected(false);
				setMemberAvatarImage(result.data.content.avatarImage);
				setMemberAvatarImageBackup(result.data.content.avatarImage);
			}
			else {
				x.wah.addHint('Avatar konnte nicht gespeichert werden!', true);
			}
		})
	}

	function saveDefaultAvatar() {
		x.was.axPost('Backend/webapp.php?module=Member&task=DeleteAvatar', {}, (result) => {
			if (result.data.returncode === 1) {
				setDefaultAvatarSelected(false);
				setMemberAvatarImage('');
				setMemberAvatarImageBackup('');
			}
			else {
				x.wah.addHint('Avatar konnte nicht zurückgesetzt werden!', true);
			}
		})
	}

	function cancelDefaultAvatar() {
		setMemberAvatarImage(memberAvatarImageBackup);
		setDefaultAvatarSelected(false);
	}

	function cancelAvatarImage() {
		setAvatarImageSelected(false);
	}

	const formButtons =
		<>
			<button type="button" className="btn " onClick={changePassword} >Kennwort ändern</button>
			<button type="button" className="btn " onClick={createToken} >Token erstellen</button>
		</>
	return (
		<Module id={props.id} x={x} title={props.title} display={props.display} displayTitle="d-block" closeButton="1">
			<div className="settings">
				<fieldset className="form-group mb-2  avatar-fieldset" >
					<legend className="float-none w-auto">Avatar</legend>
					<div className='d-flex'>
						<div className="avatar">
							<span className={` ${(avatarImageSelected) ? 'd-none' : ''} `}>
								<Avatar memberLevel={memberLevel} image={memberAvatarImage} avatarText={memberName[0]} size="xlarge" />
							</span>
							<img id="new-avatar" alt="Avatar" className={`new-avatar ${(avatarImageSelected) ? '' : 'd-none'} `} src={newAvatarUrl} />
						</div>
						<span className={`toolbar-avatar ${(avatarImageSelected || defaultAvatarSelected) ? 'd-none' : ''} `}>
							<Avatar
								memberLevel={memberLevel}
								avatarText={memberName[0]}
								title="Standard-Avatar wiederherstellen"
								onClick={(e) => {
									setDefaultAvatar();
								}}
							/>
						</span>
						<div>
							<span className={`toolbar-avatar ${(avatarImageSelected || defaultAvatarSelected) ? 'd-none' : ''} `}>
								<i className="bi bi-download module-icon" title="Avatar hochladen" onClick={e => setImage(e)}></i>
							</span>
							<span className={`toolbar-save-avatar ${(avatarImageSelected) ? '' : 'd-none'} `}>
								<i className="bi bi-check module-icon" title="Avatar speichern" onClick={e => saveAvatarImage(e)}></i>
								<i className="bi bi-x module-icon" title="Abbrechen" onClick={e => cancelAvatarImage(e)} ></i>
							</span>
							<span className={`toolbar-save-avatar ${(defaultAvatarSelected) ? '' : 'd-none'} `}>
								<i className="bi bi-check module-icon" title="Avatar speichern" onClick={e => saveDefaultAvatar(e)}></i>
								<i className="bi bi-x module-icon" title="Abbrechen" onClick={e => cancelDefaultAvatar(e)} ></i>
							</span>
						</div>
					</div>

					<div className="avatar-hint">
						Bildvorgaben:
						<ul>
							<li>PNG-Format</li>
							<li>Quadratisch</li>
							<li>Mindestens 128x128 Pixel</li>
							<li>Idealerweise mit transparentem Hintergrund</li>
						</ul>
					</div>
				</fieldset>

				<Form x={x}
					wafId={wafId}
					module="Member"
					readTask="ReadMemberSettings"
					sendTask="SaveMemberSettings"
					focusElement="name"
					classes="member-form"
					buttons={formButtons}>
					<fieldset className="form-group mb-2">
						<legend className="float-none w-auto">Allgemein</legend>
						<FormElement x={x} wafId={wafId} disabled={true} id="memberId" title="Mitglied Nr.:" />
						<FormElement x={x} wafId={wafId} disabled={true} id="memberName" title="Pseudonym:" />
						<FormElement x={x} wafId={wafId} disabled={true} id="memberLevel" title="Status:" />
					</fieldset>

					<fieldset className="form-group mb-2">
						<legend className="float-none w-auto">Kontaktdaten</legend>
						<FormElement x={x} wafId={wafId} id="name" title="Name" />
						<FormElement x={x} wafId={wafId} id="firstName" title="Vorname" />
						<FormElement x={x} wafId={wafId} id="mail" title="Mail" />
					</fieldset>

					<fieldset className="form-group mb-2">
						<legend className="float-none w-auto">PageStore</legend>
						<FormElement x={x} wafId={wafId} id="pageStoreToken" maxLength={36} title="Token" fieldValue="" />
					</fieldset>

					<fieldset className="mb-2">
						<legend>Forum</legend>
						<FormElement type="checkbox" x={x} wafId={wafId} id="darkTheme" title="Dunkelmodus" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="darkThemeVariant" title="Alternatives Design (Nur Dunkelmodus)" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="forumScroll" title="Horizontal scrollen" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="forumHideImages" title="Bilder ausblenden" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="forumHideContent" title="Inhalte ausblenden" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="forumHideDiscussion" title="Diskussion ausblenden" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="forumHideAvatar" title="Avatar ausblenden" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="forumHideDate" title="Datum ausblenden" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="forumMemberColored" title="Name des Verfassers farbig anzeigen" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="forumFontBold" title="Fette Schrift" />
						<FormElement type="checkbox" x={x} wafId={wafId} id="forumSendWithEnter" title="Posting mit Enter senden" />
					</fieldset>
					<fieldset className="mb-2">
						<legend>Statistik</legend>
						<FormElement x={x} wafId={wafId} disabled={true} id="postingCount" title="Postings:" />
						<FormElement x={x} wafId={wafId} disabled={true} id="lastLogin" title="Letzte Anmeldung:" />
						<FormElement x={x} wafId={wafId} disabled={true} id="lastRequest" title="Letzter Zugriff:" />
					</fieldset>
				</Form>
				<DialogChangePassword id={dialogPasswordId} x={x} />
			</div>
		</Module>
	);

}

