import React from 'react';
import Block from 'Components/Core/Block';
import Menu from 'Components/App/Menu';
import NewThreadShort from 'Components/Forum/NewThreadShort';
import axios from "axios";
import 'CSS/App/NavigationTop.css';

export default function NavigationTop(props) {

    const x = props.x;

	let menuSource = '';                // Enthält die Menü-Daten vom Server. Diese werden dann in der Funktion makeMenu aufbereitet. 
	let newPostingClass = 'd-block';

	// x.was.openNewThread = openNewThread;

	if (window.webapp.system.webapp === 1) {
		window.webapp.system.webapp = 2;
		getNavigation();
	}

	function getNavigation() {
		let rc = true;
		// let url = document.location.href + '/Backend/webapp.php?module=System&task=Navigation';
		let url = '/Backend/webapp.php?module=System&task=Navigation';
		axios.get(url)
			.then(result => {
				// console.log(result.data);
				if (result.data['returncode'] === 1) {
					newPostingClass = result.data['content']['newPostingClass'];
					menuSource = result.data['content']['navigation'];
					// setMenu(makeMenu(menuSource));
					makeMenus(menuSource, 'main', 'Hauptmenü', '');
					rc = true;
				}
				else {
					// x.was.activateModule('login', false);
					x.wam.activate('login');
					rc = false;
				}
			})
		return rc;
	}

	function makeMenus(menuSource, id, title, parentId) {
		// console.log('makeMenu: ' + id);
		x.was.addNavigation(<Menu menuSource={menuSource} id={'menu-' + id} x={x}   title={title} menuClick={menuClick} parentId={parentId} />);
		menuSource.forEach(
			function (item, index) {
				if (typeof item.children != 'undefined') {
					if (item.children.length > 0) {
						makeMenus(item.children, item.id, item.title, 'menu-' + id);
					}
				}
			}
		)
		// activateModule('forum');
	}


	function menuClick(item) {
		// event.preventDefault();
		//console.log(item);
		x.wan.hideAll();
		switch (item.module) {
			case 'submenu':
				x.was.activateNavigation(item.href);
				break;
			case 'function':
				x.was[item.href]();
				break;
			case 'extern':
				window.open(item.href);
				break;
			case 'logout':
				x.was.logout();
				break;
			default:
				x.wam.activate(item.module);
		}
	}

	function openNewThread() {
		const forumId = x.wam.getForumId();
		const wafId = forumId + '-new-thread';
		const newPostingShort = x.waf.get('new-thread-short', 'new-posting-short');

		const postingBackup = x.wau.postingRestore(wafId, 0);

		if (postingBackup === null || postingBackup.trim() === '') { 
			x.wau.postingBackup(wafId, 0, newPostingShort);
			x.waf.set(forumId + '-new-thread', 'posting', newPostingShort);
		}
		x.wam[forumId].showDialogNewThread();
		x.wam[forumId].setTab('postings'); // Funktion wird in tabs.js definiert
		x.wam.activate(forumId);
	}

	function activateHome() {
		x.wam.activate('forum');
		x.wam['forum'].scrollTop();
	}
	// const props.forumUnread = (typeof x.was.postingsUnread === 'undefined') ? 0 : x.was.postingsUnread.forum;
	const unreadHint = (props.forumUnread > 0) ? " (" + props.forumUnread + ((props.forumUnread > 1) ? " ungelese Beiträge)" : " ungelesener Beitrag)") : '';
	// console.log(props.forumUnread);
	return (
		<Block key="navigation-top" id="navigation-top " x={x} display="d-block" classes={`navigation navigation-top `} >

			<i id="home-icon" className={`bi ${(props.forumUnread > 0) ? 'bi-house-fill' : 'bi-house'} ' navigation-icon`} title={x.was.application + " anzeigen " + unreadHint} onClick={activateHome}>
				<span
					className={` ${props.forumUnread > 0 ? '' : 'd-none'} new-postings-icon-count `}
				>
					{(props.forumUnread > 99) ? "++" : props.forumUnread}
				</span>

			</i>
			<i id="new-posting-icon" className="bi bi-chat-left-text navigation-icon" title="Eingabeformular öffnen" onClick={event => openNewThread(false)}></i>
			<NewThreadShort x={x}   newPostingClass={newPostingClass} />
			<i className="bi bi-list navigation-icon" title="Navigation" onClick={e => x.was.activateNavigation('menu-main')}></i>
		</Block>


	);

}
