import React from 'react';
import 'CSS/FormSelect.css';
import DialogSelectText from './DialogSelectText';

export default function FormSelect(props) {

	const [makeTable, setMakeTable] = React.useState(0);
    const x = props.x;
	const id = props.id + '-form';
	const fieldId = props.fieldId;
	// const dialogId = props.id + '-dialog';
	const dialogId = props.id + '-dialog';
	const dialogClasses = (typeof props.dialogClasses !== 'undefined') ? props.dialogClasses : '';
	const onSetData = (typeof props.onSetData !== 'undefined') ? props.onSetData : setDefaultData;
	const labelClasses = (typeof props.labelClasses === 'undefined') ? null : props.labelClasses;
	const wafId = x.wau.getProperty(props.wafId, props.id);
	const disabled = x.wau.getProperty(props.disabled, false);
	const refInput = React.useRef(null);

	x.waf.init(wafId);
	x.waf[wafId][fieldId] = {};

	x.wad.init(dialogId);
	x.wad[dialogId].setValue = setSelectValue;

	React.useEffect(() => {
		 x.waf.initField(
			wafId,
			fieldId,
			refInput,
			(typeof props.default == 'undefined') ? '' : props.default,
			(typeof props.name == 'undefined') ? id : props.name
		);
		 x.waf.setKey(wafId, fieldId, props.defaultKey);
		 x.waf.mandatory(wafId, fieldId, (typeof props.mandatory === 'undefined') ? false : props.mandatory);
		if (typeof props.defaultKey !== 'undefined')  x.waf.setKey(wafId, fieldId, props.defaultKey);
		// makeOptions();
		return () => {
		}
	}, [props, refInput, fieldId, wafId, x.waf, id]);

	function setDefaultData() {
			const data = 
			[
				{'id': 1, 'name': 'k'},
			]
			return data;
	}

	function setSelectValue(key, value) {
		if (typeof props.setSelect !== 'undefined') {
			props.setSelect(key, value)
		}
		else {
			x.waf.set(wafId, props.fieldId, value);
			x.waf.setKey(wafId, props.fieldId, key);
			x.waf.setValid(wafId, props.fieldId, true);
			if (typeof props.afterSelect !== 'undefined') props.afterSelect(key, value)
		}
	}

	function showSelectionList() {
		setMakeTable(true);
		x.wad[dialogId].showSelection();
	}

	const inputClick = (typeof props.inputClick === 'undefined' || !props.inputClick) ? showSelectionList : props.inputClick
	const onReloadClick = (typeof props.onReloadClick !== 'undefined') ? props.onReloadClick : () => { };
	const onResetClick = (typeof props.onResetClick !== 'undefined') ? props.onResetClick : () => { setSelectValue(0, '') };

	return (
		<>
			<div className={`input-group input-group-sm select-text ${(typeof props.classes !== 'undefined') ? props.classes : ''}`} >
				<div className="webapp-form-item mb-2">
					<label htmlFor={props.id} className={` form-label ${labelClasses} `}>{props.title}</label>
					<input
						type="text"
						id={fieldId}
						ref={refInput}
						title={props.title}
						className={`form-control form-control-sm form-select-input ${(props.hiddenInput) ? 'd-none' : ''}`}
						placeholder={props.title}
						readOnly={true}
						disabled={disabled}
						onClick={e => inputClick()}
					/>
					{(typeof props.reloadIcon !== 'undefined' && props.reloadIcon)
						?
						<span className="input-group-text input-reset" onClick={onReloadClick}>
							<span className={` bi bi-arrow-clockwise `}
								title="Reload"
							/>
						</span>
						:
						null
					}
					{(typeof props.resetIcon !== 'undefined' && props.resetIcon)
						?
						<span className="input-group-text input-reset" onClick={onResetClick}>
							<span className={` bi bi-x `}
								title="Auswahl löschen"
							/>
						</span>
						:
						null
					}
					{(typeof props.customIcon !== 'undefined')
						?
						<span className="input-group-text " onClick={(typeof props.customClick !== 'undefined') ? props.customClick : inputClick}>
							<span className={` bi ${props.customIcon} `}
								title={props.customTitle}
							/>
						</span>
						:
						null
					}
				</div>
			</div>
			<DialogSelectText
				id={props.id}
				moduleId={props.moduleId}
				x={x}
				makeTable={makeTable}
				onSetData={() => {return onSetData()}}
				title={props.title}
				classes={dialogClasses}
			/>
		</>
	)
};
