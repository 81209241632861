import React from 'react';
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/DialogForm.css';

export default function DialogForm(props) {

    const x = props.x;

	const classes = (typeof props.classes  === 'undefined') ? '' : props.classes
	const module = (typeof props.module  === 'undefined') ? "" : props.module;
	const onAfterSend = (typeof props.onAfterSend == 'undefined') ? () => {} : props.onAfterSend;
	const sendTask = (typeof props.sendTask  === 'undefined') ? "" : props.sendTask;

	x.wad.init(props.id, {
		"setFocus": props.setFocus
	});

	return (

		<Dialog id={props.id} x={x}   title={props.title} classes={'dialog-form ' + classes } closeIcon="1" >
			<Form 
				x={x}   
				wafId={props.wafId} 
				dialogId={props.id} 
				module={module}
				sendTask={sendTask}
				classes="webapp-form" 
				buttons={props.buttons}
				sendButton={x.wau.getProperty(props.sendButton, true)}
				onAfterSend={onAfterSend}
			>
				{props.children}
				<FormElement type="message"
					x={x}  
					wafId={props.wafId}
					id="message"
					title="Hinweis"
				/>

			</Form>
		</Dialog>

	)
};
