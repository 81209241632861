import React from "react";
import Module from 'Components/Core/Module';
import Form from "Components/Core/Form/Form";
import FormElement from '../Core/Form/FormElement';
// import 'CSS/App/HintToMembers.css';

export default function HintToMembers(props) {

    const x = props.x;
	const wafId = props.id;

	React.useEffect(() => {
		if (! x.was.isMobileDevice()) x.waf.focus('hint-to-members', 'hint');
	});



	x.waf.init(wafId, {
	});

	x.wam.init(props.id, {
		"title": 'Nachricht an alle',
		"onClose": () => {
			x.waf.destroy(wafId);
		}
	});

	function onSubmit(event) {
		event.preventDefault();
		x.was.send('hint', { "hint": x.waf.get(wafId, 'hint') });
		x.waf.set(wafId, 'hint', '');
	}

	return (
		<Module id={props.id} x={x} title={props.title} display={props.display}  displayTitle="d-block" closeButton="1">
			
			<Form 	x={x}   
					wafId={wafId} 
					module="Member" 
					sendButtonTitle="Senden"
					onSubmit={(e) => onSubmit(e)}
					focusElement="hint" 
					classes="">	
					<FormElement x={x}   wafId={wafId} id="hint" title="Hinweis" />
			</Form>
		</Module>
	);

}

