import React from "react";
import Posting from "./Posting";
import 'CSS/Forum/PostingsList.css';

export default function PostingList(props) {

    const x = props.x;
	const [postingSource, setPostingSource] = React.useState([]);       // Enthält die Posting-Daten vom Server. Diese werden dann in der Funktion makePostings aufbereitet. 
	const onAfterReadPostings = (typeof props.onAfterReadPostings === 'undefined') ? () => {} : props.onAfterReadPostings;

	let addUrl = (typeof props.addUrl === 'undefined') ? '' : props.addUrl;
	let postingsCount = 0;
	let wafId = props.dialogId;

	React.useEffect(() => {
		// x.wam[props.moduleId][props.functions.loadPostings] = getPostings;
		props.onRendered({
			"addSearchUrl": setAddUrl,
			"searchPostings": getPostings,
			"reset": reset,
		})
	});


	x.waf.init(wafId, {
		'setPostingSource':setPostingSource
	});

	// x.wam.init(props.moduleId);

	/*
	if (typeof props.functions != 'undefined') {
		if (typeof props.functions.setAddUrl !== 'undefined') x.wam[props.moduleId][props.functions.setAddUrl] = setAddUrl; 
		if (typeof props.functions === 'function') {
			props.functions(setAddUrl, getPostings);
		}
	}
	*/

	function setAddUrl(add) {
		addUrl = add
	}

	function getPostings() {
		// console.log(addUrl);
		let url = '/Backend/webapp.php?module=' + props.module + '&task=' + props.task + '&forumId=' + props.forumId + addUrl;
		x.was.axGet(url, handleGetPostings);
	}

	function handleGetPostings(result) {
		if (result.data['returncode'] === 1) {
			setPostingSource(result.data['content']);
			onAfterReadPostings(result);
		}
		else {
			console.error('Error reading result');
		}
	}

	function reset() {
		setPostingSource([]);
	}
	
	function showArchive(event, threadNo, postingNo, postDate) {
		// console.log(event);
		x.wam[props.moduleId].setTab('archive');
		x.wam[props.moduleId].archive.setSelectDate(postDate.split(' ')[0]);
		x.wam[props.moduleId].archive.load(postingNo);
	}

	function makePostings(source) {
		let postingTags = [];
		let title = null;
		postingsCount = 0;
		source.forEach(
			function (item, index) {
				postingsCount++;
				title = 
					<span 
						key={'title-' + item.postingno} 
						id={'title-' + item.postingno}
						dangerouslySetInnerHTML={{ __html: item.title }}
					/> 
				postingTags.push(
					<div key={item.postingno} id={item.postingno} className="result-row" >
						<Posting
							key={postingsCount}
							x={x}  
							moduleId={props.moduleId}
							mode="list"
							posting={item}
							getPostings={() => {
								console.log(item.title);
							}}
							hideMember={x.wau.getProperty(props.hideMember, false)}
							content={null}
							title={title}
							paddingLeft={0}
							postingQuote={props.postingQuote}
							copyToClipboard={props.copyToClipboard}
							showDialogAnswer={props.showDialogAnswer}
							setTopic={() => {}}
							onClick={(e) => {showArchive(e, item.threadno, item.postingno, item.postdate)}}
						/>
					</div>
				);
			}
		);
		if (postingsCount > 0) {
			postingTags.push(
				<div key="posting-count" className="posting-count" >
					{postingsCount} Beiträge
				</div>
			)
		}
		return postingTags;
	}
	

	return (
		<div id={props.moduleId + '-' + props.id} className={`div-postings-list  ${(typeof props.classes == 'undefined') ? '' : props.classes} `} >
			<div className="div-result">
				{makePostings(postingSource, props.addUrl)}
			</div>
		</div>
	);


}
