import React from 'react';
import axios from "axios";
import ModuleTable from 'Components/Core/ModuleTable';
import FormElement from 'Components/Core/Form/FormElement';
import FormSelect from 'Components/Core/Form/FormSelect';
import './Pages.css';

export default function Pages(props) {

	const x = props.x;
	const wafId = props.id;
	const dialogFormId = "form-" + props.id;

	const formFields =
		<React.Fragment>
			<FormSelect
				id='select-section'
				fieldId='sectionId'
				wafId={wafId}
				name="sectionId"
				x={x}
				classes="form-select-topic"
				default=""
				defaultKey={0}
				title="Kategorie"
				onSetData= { () => {
					const getSections = async () => {
						const data = [{'id': 0, 'name': 'Keine Kategorie'}];
						const url = '/Backend/webapp.php?module=PageStore&task=SectionList';
						const resultData = await axios.get(url);
						resultData.data.content.forEach(
							function (item) {
								data.push({
									"id": item.sectionid,
									"name": item.name
								});
							}
						);
						return data;
					};
					return getSections();
				}}
				resetIcon={false}
			/>

			<FormElement type="text"
				x={x}
				wafId={wafId}
				id="name"
				mandatory={true}
				title="Seite"
				fieldValue=""
				maxLength="50"
			/>
			<FormElement type="text"
				x={x}
				wafId={wafId}
				id="url"
				mandatory={true}
				title="URL"
				fieldValue=""
				maxLength="2048"
			/>
			<FormElement type="text"
				x={x}
				wafId={wafId}
				id="faviconurl"
				mandatory={true}
				title="Favicon"
				fieldValue=""
				maxLength="2048"
			/>
			<FormElement type="textarea"
				x={x}
				wafId={wafId}
				id="description"
				mandatory={false}
				title="Beschreibung"
				fieldValue=""
				maxLength="200000"
			/>
			<FormElement type="checkbox" x={x} wafId={wafId} id="top" title="Zuerst anzeigen" />
			<FormElement type="checkbox" x={x} wafId={wafId} id="hidden" title="Versteckt" />
			<FormElement type="checkbox" x={x} wafId={wafId} id="disabled" title="Inaktiv" />

		</React.Fragment>

	return (
		<ModuleTable
			id={props.id}
			tableId="id"
			dialogFormId={dialogFormId}
			x={x}
			classes={`module-page-store pages-table`}
			title={props.title}
			addDeleteRow={true}
			addTitle="Neue Seite anlegen"
			module="PageStore"
			listTask="PageList"
			deleteTask="PageDelete"
			onAddHeader={(addHeader) => {
				addHeader('Rubrik');
				addHeader('Seite');
				addHeader('URL');
				addHeader('Top');
				addHeader('Versteckt');
				addHeader('Inaktiv');
			}}
			onAddColumn={(key, value) => {
				switch (key) {
					case 'sectionName':
					case 'name':
					case 'url':
					case 'top':
					case 'hidden':
					case 'disabled':
						return value;
					default:
						console.log('unknown key: ' + key);
						return false;
				}
			}}
			onEditRow={(e, rowFields, rowId) => {
				x.waf.setAddSend(wafId, 'id', rowFields.pageId);
				x.waf.set(wafId, 'sectionId', rowFields.sectionName);
				x.waf.setKey(wafId, 'sectionId', rowFields.sectionId);
				x.waf.set(wafId, 'name', rowFields.name);
				x.waf.set(wafId, 'url', rowFields.url);
				x.waf.set(wafId, 'faviconurl', rowFields.faviconurl);
				x.waf.set(wafId, 'description', rowFields.description);
				x.waf.set(wafId, 'top', rowFields.top);
				x.waf.set(wafId, 'hidden', rowFields.hidden);
				x.waf.set(wafId, 'disabled', rowFields.disabled);
				x.waf.hide(wafId, 'message');
				x.wad[dialogFormId].setTitle("Seite bearbeiten");
				x.wad[dialogFormId].show(() => { x.waf.focus(wafId, 'name') });
			}}
			formFields={formFields}
			formSendTask="PageSave"
		/>
	)


};

