import React from 'react';
import './Table.css';
// import * as Constants from 'Components/App/Constants.js';


export default function Table(props) {

	const x = props.x;

	const renderCount = React.useRef(0);
	const [content, setContent] = React.useState('');
	const [resultSet, setResultSet] = React.useState([]);

	const addCloseButton = (typeof props.addcloseButton === 'undefined') ? false : props.addcloseButton;
	const addDeleteRow = (typeof props.addDeleteRow === 'undefined') ? false : props.addDeleteRow;
	const deleteRowAllowed = (typeof props.deleteRowAllowed === 'undefined') ? false : props.deleteRowAllowed;
	const display = (typeof props.hideTable === 'undefined') ? false : (props.hideTable) ? 'd-none' : '';
	const onAddColumn = (typeof props.onAddColumn === 'undefined') ? (key, value) => { return key } : props.onAddColumn;
	const onAddFooter = (typeof props.onAddFooter === 'undefined') ? () => { return null } : props.onAddFooter;
	const onAddHeader = (typeof props.onAddHeader === 'undefined') ? () => { return null } : props.onAddHeader;
	const onLoad = (typeof props.onLoad === 'undefined') ? () => { return null } : props.onLoad;
	const onRowClasses = (typeof props.onRowClasses === 'undefined') ? () => { return '' } : props.onRowClasses;
	const onRowEdit = (typeof props.onRowEdit === 'undefined') ? () => { } : props.onRowEdit;
	const onRowDelete = (typeof props.onRowDelete === 'undefined') ? (e, rowFields, rowId) => { rowDelete(rowId) } : props.onRowDelete;


	const table = {};
	init();

	React.useEffect(() => {
		if (renderCount.current < props.loadTable) {
			renderCount.current = props.loadTable;
			load();
		}
	});

	function load() {
		const data = onLoad();
		if (data === null) {
			const url = 'Backend/webapp.php?module=' + props.module + '&task=' + props.listTask;
			x.was.axGet(url, (result) => {
				if (result.data['returncode'] === 1) {
					// setResultSet(result.data['content']);
					makeTable(result.data['content']);
				}
				else {
					x.wah.addHint('Error while reading table!', true);
				}
			});
		}
		else {
			setResultSet(data);
			makeTable(data);
		}
	}

	function rowClicked(e, rowFields, rowId) {
		e.preventDefault();
		if (e.target.getAttribute("deleterow") === '1') {
			if (deleteRowAllowed) {
				x.wau.yesNo("Wirklich löschen?",
					(result) => {
						console.log(result);
						if (result === 1) onRowDelete(e, rowFields, rowId);
						return;
					});
			}
			else {
				x.wah.addHint('Keine Berechtigung!');
			}
		}
		else {
			onRowEdit(e, rowFields, rowId);
		}
	}


	function rowDelete(rowId) {
		const url = 'Backend/webapp.php?module=' + props.module + '&task=' + props.deleteTask
		x.was.axPost(url, { "id": rowId }, (result) => {
			if (result.data['returncode'] === 1) {
				load();
			}
			else {
				x.wah.addHint('Fehler beim Löschen der Rubrik!', true);
			}
		})
	}

	function addFooter(content, columnClass) {
		table.rowFooterCount++
		if (typeof columnClass == 'undefined') {
			columnClass = '';
		}
		table.rowFooter.push(<span
			key={table.rowFooterCount}
			className={`column footer-column ${columnClass}  ${(table.rowFooterCount > 1) ? 'multicolumn' : ''} footer-column-${table.rowFooterCount}`}
		>
			{content}
		</span>);
	}

	function addHeader(content, columnClass) {
		table.rowHeaderCount++
		if (typeof columnClass == 'undefined') {
			columnClass = '';
		}
		table.rowHeader.push(
			<span
				key={table.rowHeaderCount}
				className={`column header-column ${columnClass}  ${(table.rowHeaderCount > 1) ? 'multicolumn' : ''} header-column-${table.rowHeaderCount}`}
			>
				{content}
			</span>);
	}

	function makeTable(data = resultSet) {
		init();
		const row = [];
		let columnTags = [];
		let columnCount = 1;
		let rowCount = 0;
		let rowClasses;

		onAddHeader(addHeader);
		onAddFooter(addFooter);

		data.forEach(
			function (item) {
				let rowId = null;
				columnCount = 1;

				// Add Columns
				columnTags = [];
				Object.entries(item).forEach(([key, value]) => {
					const column = onAddColumn(key, value);
					if (rowId === null) {
						rowId = value;
					}
					if (column !== false) {
						columnTags.push(
							<span
								key={"table-column-" + rowCount + "-" + columnCount}
								className={`column column-${columnCount} ${(columnCount > 1) ? 'multicolumn' : ''}`}
								title={(typeof column === 'object') ? '' : column}
							>
								{column}
							</span>
						);
						columnCount++;
					}
				});

				// Add Delete Column
				if (addDeleteRow) {
					columnTags.push(
						<span
							key={"table-column-" + rowCount + "-" + columnCount}
							className={`column column-${columnCount} ${(columnCount > 1) ? 'multicolumn' : ''}`}
						>
							<i
								className="bi bi-trash3"
								deleterow="1"
							/>
						</span>);
				}

				// Add Row
				rowClasses = onRowClasses(item);
				row.push(
					<div
						key={"table-row-" + rowCount}
						onClick={(e) => rowClicked(e, item, rowId)}
						className={`table-row ${rowClasses}`}
					>
						{columnTags}
					</div>
				)
				rowCount++;
			}
		)

		const headerTag = makeHeaderTag();
		const footerTag = makeFooterTag();

		const content =
			<div id={props.id} key="table" className={`table-basis ${props.classes} ${display}`} >
				<div key="table-header" className='table-header'>
					{headerTag}
				</div>
				<div key="table-content" className='table-content'>
					{row}
				</div>
				{footerTag}
			</div>
		setContent(content);
	}

	function makeHeaderTag() {
		if (table.rowHeader.length > 0) {
			if (addCloseButton) addHeader(<i className="bi bi-x-lg table-icon" onClick={e => x.wab[props.id].hide()} />, 'table-close-button');
			if (addDeleteRow) addHeader(<i className="bi bi-trash3" />);
			return (
				<div key="table-header-row"
					className={` table-header-row  
										${(typeof props.headerClass == 'undefined')
							? ''
							: props.HeaderClass} ${(table.rowHeaderCount < 2) ? 'table-header-center' : 'table-header-left'} `
					}>
					{table.rowHeader}
				</div>
			)
		}
		else {
			return null;
		}
	}

	function makeFooterTag() {
		if (table.rowFooter.length > 0) {
			return (
				<div key="table-footer"
					className={` table-footer 
							${(typeof props.footerClass == 'undefined')
							? ''
							: props.footerClass}  `
					}>
					{table.rowFooter}
				</div>
			)
		}
		else {
			return null;
		}
	}

	function init() {
		table.rowHeader = [];
		table.rowFooter = [];
		table.rowHeaderCount = 0;
		table.rowFooterCount = 0;
	}

	return (
		<>
			{content}
		</>
	);

}
