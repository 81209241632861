import React from 'react';
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/Forum/DialogPreview.css';

export default function DialogPreview(props) {

    const x = props.x;

	x.waf.init(props.id);
	x.wad.init(props.id);

	return (

		<Dialog id={props.id} x={x}   title={props.title} classes="dialog-preview" closeIcon={true}  >
			<Form 	x={x}   
					wafId={'preview-posting-' + props.id}
					dialogId={props.id} 
					classes="webapp-form" 
					sendButtonTitle="Beitrag erstellen" 
					enableSendButton={true} 
					onSubmit={props.onSubmit}
			>
				<FormElement type="div" x={x}   wafId={props.id} readOnly={true} id="preview-posting" title="Text" />
			</Form>
		</Dialog>

	)
};
