import React from 'react';
import 'CSS/FormSelect.css';

export default function FormSelectImage(props) {

    const x = props.x;

	const [display, setDisplay] = React.useState((typeof props.display === 'undefined') ? 'd-none' : props.display);
	const [content, setContent] = React.useState('');
	const reference = React.useRef(null);
	const classes = (typeof props.classes === 'undefined') ? '' : props.classes;

	const wafId = props.wafId;
	const id = props.id;
	
	const divId = 'div-' + id;

	x.waf.init(wafId);
	x.waf[wafId][id] = {};
	x.waf[wafId][id].show = show;
	x.waf[wafId][id].hide = hide;

	React.useEffect(() => {
		reference.current.focus();
	});

	React.useEffect(() => {
		x.was.addWatchClick(props.componentId, (event) => {
			if (x.$(event.target).closest("#" + divId).length === 0 && event.target.id !== props.callId) {
				hide();
			}
			if (event.target.id === id) {
				setDisplay('d-block');
			} 
		});
		(typeof props.makeContent == 'undefined') ? makeContent(reference.current.value) : props.makeContent('');
		return () => {
            x.was.delWatchClick(props.componentId);
        }
	}, [props, reference, x, id, divId]);

	function show(event, showSelection = false) {
		event.preventDefault();
		reference.current.value = '';
		setDisplay('d-block');
		setContent(props.makeContent(''));
	}

	function hide() {
		setDisplay('d-none');
	}

	function onChange(event) {
		reference.current.value = event.target.value;
		setContent(props.makeContent(event.target.value));
	}

	function makeContent() {

	}

	return (
		<span id={divId} className={`select-image ${classes} ${display}`} >
			<input 
				id={id} 
				type="text" 
				ref={reference} 
				className="form-control " 
				onChange={onChange} 
			/>
			<div id={'result-' + id} className='image-selection'>
				<span className={``}>{content}</span>
			</div>
		</span>
	)
};
