import React from "react";

export default function WebappDialog(props) {

    const x = props.x;
	const renderCount = React.useRef(1);

	React.useEffect(() => {
		if (renderCount.current === 1) {
			// Zeiger auf Funktionen
			x.wad.addTag = addTag;
			x.wad.hideAll = hideAll;
			x.wad.toggleDisplay = toggleDisplay;
			x.wad.setDisplay = setDisplay;
			x.wad.init = init;
			props.childRendered();			
		}
		renderCount.current++;
	});

	function addTag(tag) {
		x.wad.tags[tag.props.id] = tag;
	}

	function setDisplay(id, visible) {
		x.wad[id].isVisible = visible;
	}

	function toggleDisplay(id) {
		x.wad[id].isVisible = ! x.wad[id].isVisible;
	}

	function init(id, settings) {
		if (typeof x.wad[id] === 'undefined') {
			x.wad[id] = {};
			x.wad[id].isVisible = false;
			x.wad[id].focusElement = null;
			x.wad[id].hide = () => {};
			x.wad[id].init = () => {};
			x.wad[id].onClose = () => {};
			x.wad[id].onAfterRender = () => {};
			x.wad[id].setTitle = () => {};
			x.wad[id].show = () => {};
		}
		if (typeof settings !== 'undefined') {
			x.wad[id] = Object.assign(x.wad[id], settings);
		}

	}

	function hideAll(currentDialog) {
        for (const key in x.wad) {
			if (typeof x.wad[key].hide != 'undefined' && key !== currentDialog) {
				x.wad[key].hide();
				x.wam[x.wam.current].onDialogClose(key);
			}
        }
    }

	return (
		<React.Fragment />
	);

}

