import React from "react";
import Dialog from "Components/Core/Dialog";
import PostingList from "./PostingList";
import FormSelectText from "Components/Core/Form/FormSelectText";
import DialogSelectText from "Components/Core/Form/DialogSelectText";

export default function PostingMember(props) {

    const x = props.x;
	const memberId = props.moduleId + '-member';
	const postingMemberId = props.postingMemberId;
	const postingList = [];										// Hier werden Zeiger auf die benötigten Funktionen der PostingList-Komponenten gespeichert

	function setMembersData() {
		const members = [];
		Object.entries(x.was.members).forEach(([key, value]) => {
			members.push({ "id": key, "name": value.name });
		  });
  
		const sortedMembers = sortMembers(members);
		return sortedMembers;
	}

	function sortMembers(members) {
		return members.sort(function(a, b){
			let x = a.name.toLowerCase();
			let y = b.name.toLowerCase();
			if (x < y) {return -1;}
			if (x > y) {return 1;}
			return 0;
		  });
	}
	
	const selectTag = 	<FormSelectText
							id={postingMemberId}
							moduleId={props.moduleId}
							x={x}  
							default=""
							defaultKey={0}
							resetIcon={true}
							title="Wählen Sie hier ein Mitglied aus..."
							afterSelect={(key, value) => {
								let addUrl = '&memberid=' + key;
								if (key === 0) {
									postingList.reset();
								}
								else {
									postingList.addSearchUrl(addUrl) ;
									postingList.searchPostings();
								}
							}}
						/>

	return (
		<React.Fragment>
			<Dialog id={memberId} x={x}   classes="dialog-postings-list dialog-posting-member" title={selectTag} handleClose={props.handleClose}>
				<PostingList
					id="member-postings"
					x={x}  
					forumId={props.forumId}
					moduleId={props.moduleId}
					dialogId={memberId}
					classes="member-postings"
					functionName="searchPostings"
					module="Posting"
					task="PostingMember"
					withAvatar={false}
					hideMember={true}
					showDialogAnswer={props.showDialogAnswer}
					copyToClipboard={props.copyToClipboard}
					onRendered={(functions) => {
						postingList.addSearchUrl = functions.addSearchUrl;
						postingList.searchPostings = functions.searchPostings;
						postingList.reset = functions.reset;
					}}
				/>
			</Dialog>
			<DialogSelectText
				id={postingMemberId}
				moduleId={props.moduleId}
				x={x}  
				onSetData={setMembersData}
				classes="dialog-select-member-postings"
				title="Mitglieder"
			/>

		</React.Fragment>
	);


}
